import Ajax from './base';

const GetAllRaw = (payload) => {
	return Ajax.Request('/firms/raw', Ajax.GET, true, payload);
};

const GetAll = (payload) => {
	return Ajax.Request('/firms', Ajax.GET, true, payload);
};

const GetOneByID = (fid) => {
	return Ajax.Request(`/firms/${fid}`, Ajax.GET, true);
};

const GetOneByDomainName = (domain) => {
	return Ajax.Request(`/firms/email_domain/${domain}`, Ajax.GET, true);
};

const Create = (payload) => {
	return Ajax.Request('/firms', Ajax.POST, false, payload);
};

const UpdateRegions = (firmID, payload) => {
	return Ajax.Request(`/firms/${firmID}/regions`, Ajax.PUT, false, payload);
};

const GetRegions = (firmID) => {
	return Ajax.Request(`/firms/${firmID}/regions`, Ajax.GET, false);
};

const UpdateRates = (firmID, payload) => {
	return Ajax.Request(`/firms/${firmID}/rates`, Ajax.PUT, false, payload);
};

const UpdateRateById = (rateID, payload) => {
	return Ajax.Request(`/firms/rates/${rateID}`, Ajax.PUT, false, payload);
};

const NotifyRatesChanged = (payload) => {
	return Ajax.Request(`/firms/rates/notification`, Ajax.POST, false, payload);
};

const GetPoints = (firmID, queryStringData) => {
	return Ajax.Request(`/reciprocity/transactions`, Ajax.GET, false, { firm_id: firmID, ...queryStringData });
};

const ApplyAdjustedPoints = (id, payload) => {
	return Ajax.Request(`/reciprocity/transactions/${id}/points`, Ajax.POST, false, payload);
};

const UpdateByID = (firmID, payload) => {
	return Ajax.Request(`/firms/${firmID}`, Ajax.PUT, false, payload);
};

const BulkCreateRates = (payload) => {
	return Ajax.Request(`/firms/rates`, Ajax.POST, true, payload);
};

const SendRegisterEmailWithCode = (payload) => {
	return Ajax.Request(`/firms/emails/register-code`, Ajax.POST, true, payload);
};

export default {
	GetAllRaw,
	GetAll,
	GetOneByID,
	GetOneByDomainName,
	Create,
	UpdateRegions,
	GetRegions,
	UpdateRates,
	GetPoints,
	ApplyAdjustedPoints,
	UpdateByID,
	UpdateRateById,
	NotifyRatesChanged,
	BulkCreateRates,
	SendRegisterEmailWithCode,
};
