import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';

import AppTemplate from 'com/templates/ApplicationTemplate';
import { AppRoute } from 'com/util/AppRoute';
import { RoleBox } from 'com/util/RoleBox';
import AppDate from 'com/util/AppDate';
import Routes from 'data/routes';

import QuoteDetailsViewEstimatesRules from './QuoteDetailsViewEstimatesRules';
import QuoteDetailsViewQuoteRevisions from './QuoteDetailsViewQuoteRevisions';
import QuoteDetailsViewQuoteEstimate from './QuoteDetailsViewQuoteEstimate';
import CaseDetailsSidebar from 'com/widgets/CaseDetailsSidebar';

import Quotes from 'services/rest/quotes';
import Firms from 'services/rest/firms';
import Users from 'services/rest/users';
import Estimates from 'services/rest/estimates';
import Settings from 'services/rest/settings';
import Wipo from 'services/wipo/wipo';

import { DOCUMENT_OBJECT_TYPE, INTERNAL_ROLES, PAGE_TITLE, SERVICE_KEYS, DEADLINE_LABELS, UTILITY_MODEL_PRIORITY_TYPE, EXTERNAL_ROLES } from 'data/constants';

import useTitle from 'services/hooks/useTitle';

import ICON_CALENDAR from 'assets/images/icons/ico_calendar.svg';
import ICON_WIPO_LINK from 'assets/images/icons/ico_metro_link.svg';
import ICON_WIPO_LINK_DISABLED from 'assets/images/icons/ico_metro_link_disabled.svg';
import './style.css';
import { OwnerContextProvider } from 'util/ownerContext';

const QuoteDetailsView = (props) => {
	useTitle(PAGE_TITLE.QUOTE_DETAILS);
	const { id } = useParams();

	const quoteDataInit = {
		id: id,
		service_id: '',
		firm: '',
		members: [],
		state: '',
	};

	const uploadDocumentsEmailPayloadInit = {
		case_number: '',
		case_id: '',
		firm_name: '',
		account_executive_id: '',
		path: '',
	};

	const [quoteData, setQuoteData] = useState(quoteDataInit);
	const [userData, setUserData] = useState({});
	const [firmData, setFirmData] = useState({});
	const [services, setServices] = useState([]);
	const [wipoLink, setWipoLink] = useState('');
	const [completedQuote, setCompletedQuote] = useState(false);
	const [objectTypeRegion, setObjectTypeRegion] = useState('');
	const [objectTypeState, setObjectTypeState] = useState('');
	const [uploadDocumentsEmailPayload, setUploadDocumentsEmailPayload] = useState(uploadDocumentsEmailPayloadInit);
	const [deadlineLabel, setDeadLineLabel] = useState('');

	useEffect(() => {
		(async () => {
			await fetchQuoteData();
		})();
	}, []);

	useEffect(() => {
		if (quoteData.state === DOCUMENT_OBJECT_TYPE.QUOTE) {
			setObjectTypeRegion(DOCUMENT_OBJECT_TYPE.QUOTE_REGION);
			setObjectTypeState(DOCUMENT_OBJECT_TYPE.QUOTE);
		} else if (quoteData.state === DOCUMENT_OBJECT_TYPE.CASE) {
			setObjectTypeRegion(DOCUMENT_OBJECT_TYPE.CASE_REGION);
			setObjectTypeState(DOCUMENT_OBJECT_TYPE.CASE);
		}
	}, [quoteData.state]);

	const fetchQuoteData = async () => {
		try {
			let quotesData = await Quotes.GetOne(id);
			setQuoteData(quotesData);
			let est = await Estimates.GetLastPublishedEstimateByQuoteId(id);
			if (est) setCompletedQuote(true);
			let user = await Users.GetOne(quotesData.client_id);
			setUserData(user);
			let firm = await Firms.GetOneByID(user.firm_id);
			setFirmData(firm);
			let servicesData = await Settings.GetServices();
			setServices(servicesData);
			setUploadDocumentsEmailPayload({
				case_number: quotesData.case_number,
				case_id: quotesData.id,
				firm_name: firm.name,
				account_executive_id: firm.account_executive_id,
				path: 'quotes',
			});
			if (quotesData && quotesData.service_id && quotesData.application_number && servicesData) {
				if (Wipo.enableGetWipo(quotesData.service_id, servicesData)) {
					let payload = Wipo.formatWipoRequestObj(quotesData.application_number, quotesData.service_id);
					let wipo = await Settings.GetWipo(payload);
					if (wipo.succeed && wipo.result.wipo_link) {
						setWipoLink(wipo.result.wipo_link);
					}
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	const serviceIdToName = (serviceId) => {
		let service_name = '';
		if (serviceId) {
			let service = services.find((s) => s.id === serviceId);
			service_name = service?.name;
		} else {
			return '';
		}
		return service_name;
	};

	const getDeadlineLabel = (quote_data) => {
		if (quote_data.service_id) {
			let service = services.find((s) => s.id === quote_data.service_id);
			if (service) {
				switch (service.key) {
					case SERVICE_KEYS.TRADEMARKS:
						return DEADLINE_LABELS.TRADEMARKS;
					case SERVICE_KEYS.EP:
						return DEADLINE_LABELS.EP;
					case SERVICE_KEYS.UTILITY:
						if (quote_data.priority_type === UTILITY_MODEL_PRIORITY_TYPE.PARIS_CONVENTION) {
							return DEADLINE_LABELS.PARIS;
						} else {
							return DEADLINE_LABELS.PCT;
						}
					case SERVICE_KEYS.PCT:
						return DEADLINE_LABELS.PCT;
					case SERVICE_KEYS.DESIGN:
						return DEADLINE_LABELS.DESIGN;
					case SERVICE_KEYS.PARIS:
						return DEADLINE_LABELS.PARIS;
					case SERVICE_KEYS.UNITARY:
						return DEADLINE_LABELS.UNITARY;
					default:
						return 'Deadline:';
				}
			}
		} else {
			return 'Deadline:';
		}
	};

	useEffect(() => {
		let dl = getDeadlineLabel(quoteData);
		setDeadLineLabel(dl);
	}, [quoteData.service_id, services]);

	const handleWipoLinkChange = (link) => {
		setWipoLink(link);
	};

	return (
		<AppTemplate title="Quote Details" headerIcon="quotes">
			<AppTemplate.Sidebar>
				<OwnerContextProvider firmId={firmData.id}>
					<RoleBox roles={[...INTERNAL_ROLES, '$firm']}>
						<CaseDetailsSidebar
							caseNumber={quoteData.case_number}
							firmName={firmData.name}
							firmId={firmData.id}
							user={`${userData.first_name} ${userData.last_name}`}
							userId={userData.id}
							title="Quote Details"
						>
							<ul className="quotes-sidebar__section__body__quote-data">
								<RoleBox roles={INTERNAL_ROLES}>
									<li>
										<span className="quote-sidebar__quote-data-left">{'WIPO Link:'}</span>
										<a className={wipoLink ? 'quote-sidebar__wipo-link' : 'quote-sidebar__wipo-link-disabled'} href={wipoLink} target="_blank">
											<img src={wipoLink ? ICON_WIPO_LINK : ICON_WIPO_LINK_DISABLED} className="quote-sidebar_quote-data-link-ico" alt="" />
										</a>
									</li>
								</RoleBox>
								<li>
									<span className="quote-sidebar__quote-data-left">{'App. No.:'}</span>
									<span className="quote-sidebar__quote-data">{quoteData.application_number}</span>
								</li>
								<li>
									<span className="quote-sidebar__quote-data-left">{'Applicant:'}</span>
									<span className="quote-sidebar__quote-data">{quoteData.applicant_name}</span>
								</li>
								<li>
									<span className="quote-sidebar__quote-data-left">{'Client Ref. No.:'}</span>
									<span className="quote-sidebar__quote-data">{quoteData.client_ref_number}</span>
								</li>
								<li>
									<span className="quote-sidebar__quote-data-left">{getDeadlineLabel(quoteData)}</span>
									<span className="quote-sidebar__quote-data">
										{quoteData.due_date ?
											<img src={ICON_CALENDAR} className="quote-sidebar_quote-data-date-ico" alt="" />
										:	null}
										<AppDate>{quoteData.due_date}</AppDate>
									</span>
								</li>
								<li>
									<span className="quote-sidebar__quote-data-left">{'Service:'}</span>
									<span className="quote-sidebar__quote-data">{serviceIdToName(quoteData.service_id)}</span>
								</li>
								<RoleBox roles={INTERNAL_ROLES}>
									<li>
										<span className="quote-sidebar__quote-data-left">{'Status:'}</span>

										<div className="quote-sidebar__quote-data-status-indicator-right">
											<div
												className={`quotes-sidebar__status-indicator ${completedQuote ? 'quotes-sidebar__status-indicator-published' : 'quotes-sidebar__status-indicator-draft'}`}
											></div>
											<span
												className={`quote-sidebar__quote-data quotes-sidebar__status-indicator-published ${completedQuote ? 'quotes-sidebar__status-indicator-published' : 'quotes-sidebar__status-indicator-draft'}`}
											>
												{completedQuote ? 'Published' : 'Draft'}
											</span>
										</div>
									</li>
								</RoleBox>
								<li>
									<span className="quote-sidebar__quote-data-left">{'Created On:'}</span>
									<span className="quote-sidebar__quote-data">
										<img src={ICON_CALENDAR} className="quote-sidebar_quote-data-date-ico" alt="" />
										<AppDate>{quoteData._created}</AppDate>
									</span>
								</li>
							</ul>
						</CaseDetailsSidebar>
					</RoleBox>
				</OwnerContextProvider>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<OwnerContextProvider firmId={firmData.id}>
					<RoleBox roles={[...INTERNAL_ROLES, '$firm']}>
						<div className="main-screen-section white no-padding quote-details">
							<header className="quote-details-view-content__header">
								<nav>
									{/* NavLink to properies are placeholders except for estimates-rules */}
									<NavLink to={`/quotes/${id}/edit`} activeClassName="active">
										General Information
									</NavLink>

									<NavLink to={`/quotes/${id}/documents`} activeClassName="active">
										Quote Documents
									</NavLink>
									<RoleBox roles={['admin', 'sales', 'sales_manager', 'estimates', 'case_manager', 'case_manager_manager', 'finance']}>
										<NavLink to={`/quotes/${id}/estimates-rules`} activeClassName="active">
											Estimate Rules
										</NavLink>
									</RoleBox>
									<RoleBox roles={INTERNAL_ROLES}>
										<NavLink to={`/quotes/${id}/revisions`} activeClassName="active">
											Estimates
										</NavLink>
									</RoleBox>
									<RoleBox roles={EXTERNAL_ROLES}>
										<NavLink to={`/quotes/${id}/estimate`} activeClassName="active">
											Estimate
										</NavLink>
									</RoleBox>
								</nav>
							</header>
							<AppRoute route={Routes.QUOTES_EDIT} handleWipoLinkChange={handleWipoLinkChange} quoteId={id} exact />
							<AppRoute route={Routes.QUOTE_DETAILS_ESTIMATES_RULES} quoteId={id} exact />
							<AppRoute route={Routes.QUOTE_DETAILS_QUOTE_REVISIONS} quoteId={id} caseNumber={quoteData.case_number} refreshStatus={setCompletedQuote} exact />
							<AppRoute route={Routes.QUOTE_DETAILS_QUOTE_ESTIMATE} quoteId={id} deadlineLabel={deadlineLabel} exact />
							<AppRoute route={Routes.QUOTES_INSTRUCT} exact />
							<AppRoute route={Routes.QUOTE_DOCUMENTS} objectTypeRegion={objectTypeRegion} objectTypeState={objectTypeState} emailData={uploadDocumentsEmailPayload} exact />
						</div>
					</RoleBox>
				</OwnerContextProvider>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export { QuoteDetailsViewEstimatesRules, QuoteDetailsViewQuoteRevisions, QuoteDetailsViewQuoteEstimate };

export default QuoteDetailsView;
