import Ajax from './base';

const Logout = () => {
	return Ajax.Request('/auth/logout', Ajax.GET, false);
};

const Login = (payload) => {
	return Ajax.Request('/auth/login', Ajax.POST, false, payload);
};

const CreateAccount = (payload) => {
	return Ajax.Request('/auth/create-account', Ajax.POST, false, payload);
};

const ValidateToken = () => {
	return Ajax.Request('/auth/validate', Ajax.GET, true);
};

const UpdateTokenData = () => {
	return Ajax.Request('/auth/update-token', Ajax.GET, true);
};

const ForgotPassword = (email) => {
	return Ajax.Request('/auth/forgot-password', Ajax.PUT, false, { email });
};

const ResetPassword = (payload) => {
	return Ajax.Request('/auth/reset-password', Ajax.POST, false, payload);
};

const ConfirmAccount = (id) => {
	return Ajax.Request(`/auth/confirm-account/${id}`, Ajax.GET, false);
};

export default {
	Logout,
	Login,
	CreateAccount,
	ValidateToken,
	ForgotPassword,
	ResetPassword,
	ConfirmAccount,
	UpdateTokenData,
};
