//System Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
//UI Imports
import AuthBox from 'com/templates/AuthBox';
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';
import Checkbox from 'com/ui/Checkbox';
import BackButton from 'com/ui/BackButton';
import Select from 'com/ui/Select';
import AppLink from 'com/util/AppLink';
import RadioButton from 'com/ui/RadioButton';
import ErrorMessage from 'com/ui/ErrorMessage';
//Local Component Imports
import FirmRegisteredInputs from 'com/pages/Register/FirmRegisteredInputs';
import NewFirmRegisterInputs from 'com/pages/Register/NewFirmRegisterInputs';
//Service Imports
import { RegisterSchema } from 'services/validators/register';
//Data Imports
import { titles } from 'data/lists';
import Routes from 'data/routes';
//Style Imports
import './style.css';

const FirmRegisterAzami = (props) => {
	return (
		<div className="register-container-azami">
			<AuthBox title="Register" titleColor="azami-blurple" icon="create_account" footer={<BackButton to="/">Back to login</BackButton>}>
				<Formik initialValues={props.initialState} enableReinitialize={true} validationSchema={RegisterSchema} onSubmit={props.handleSubmit} validateOnChange={false}>
					{({ values, handleChange, validateField, errors, status }) => {
						const validate = (e) => validateField(e.target.name);

						return (
							<Form>
								<div className="register-container__fields">
									<div className="register-container__input">
										<Select name="title" value={values.title} onChange={handleChange} error={errors.title} label="Title" placeholder="Mr." options={titles} onBlur={validate} />
									</div>
									<div className="register-container__input">
										<Input
											name="first_name"
											value={values.first_name}
											onChange={handleChange}
											error={errors.first_name}
											label="First Name"
											placeholder="John"
											type="text"
											onBlur={validate}
										/>
									</div>
									<div className="register-container__input">
										<Input
											name="last_name"
											value={values.last_name}
											onChange={handleChange}
											error={errors.last_name}
											label="Last Name"
											placeholder="Smith"
											type="text"
											onBlur={validate}
										/>
									</div>
									<div className="register-container__input">
										<Input
											name="email"
											value={values.email}
											onChange={handleChange}
											error={errors.email}
											label="Email"
											placeholder="email@domain.com"
											type="text"
											onBlur={validate}
										/>
									</div>
									<div className="register-container__input">
										<Input name="phone" value={values.phone} onChange={handleChange} error={errors.phone} label="Phone" placeholder="555 1234" type="text" onBlur={validate} />
									</div>
									<div className="register-container__input">
										<Select
											name="country"
											value={values.country}
											onChange={handleChange}
											error={errors.country}
											label="Country"
											placeholder="United States"
											options={props.countries}
											onBlur={validate}
										/>
									</div>
									<div className="register-container__input">
										<Input name="password" value={values.password} onChange={handleChange} error={errors.password} label="Password" type="password" onBlur={validate} />
									</div>
									<div className="register-container__input">
										<Input
											name="password_repeat"
											value={values.password_repeat}
											onChange={handleChange}
											error={errors.password_repeat}
											label="Repeat Password"
											type="password"
											onBlur={validate}
										/>
									</div>
								</div>
								<div className="register-container__fields">
									<div className="register-container__input">
										<RadioButton value="1" checked={values.firm_registered === '1'} onChange={handleChange} label="Firm already registered" name="firm_registered" />
									</div>
									<div className="register-container__input">
										<RadioButton value="0" checked={values.firm_registered === '0'} onChange={handleChange} label="New firm registration" name="firm_registered" />
									</div>
								</div>
								{values.firm_registered === '1' ?
									<FirmRegisteredInputs values={values} handleChange={handleChange} validate={validate} errors={errors} />
								:	<NewFirmRegisterInputs values={values} handleChange={handleChange} validate={validate} errors={errors} countries={props.countries} />}
								<div className="register-container__checkbox">
									<Checkbox
										labelClassName="user-details-content__footer__checkbox-label"
										label={
											<>
												I Agree to the{' '}
												<AppLink type="nav" to={Routes.LEGAL_INFO_TERMS_AND_CONDITIONS.path} target="_blank">
													Terms &amp; Conditions
												</AppLink>{' '}
												and{' '}
												<AppLink type="nav" to={Routes.LEGAL_INFO_ENGAGEMENT_LETTER.path} target="_blank">
													Engagement Letter
												</AppLink>{' '}
												of Azami Global
											</>
										}
										name="tos_agreement"
										value={values.tos_agreement}
										onChange={handleChange}
										error={errors.tos_agreement}
										onBlur={validate}
									/>
								</div>
								<div className="register-container__actions">
									<Button type="submit" showSpinner={props.spinner}>
										Submit
									</Button>
								</div>
								{status && <ErrorMessage error={status} />}
							</Form>
						);
					}}
				</Formik>
			</AuthBox>
		</div>
	);
};

FirmRegisterAzami.propTypes = {
	initialState: PropTypes.object,
	handleSubmit: PropTypes.func,
	countries: PropTypes.array,
	spinner: PropTypes.bool,
};

FirmRegisterAzami.defaultProps = {
	initialState: {},
	handleSubmit: () => {},
	countries: [],
	spinner: false,
};

export default FirmRegisterAzami;
