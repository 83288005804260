import { API_HOST, INVALID_DATA_ERROR, SERVER_ERROR, FORBIDDEN_ERROR, NOT_FOUND, PAYLOAD_TOO_LARGE, UNAUTHORISED } from 'data/constants';

const POST = 'POST';
const GET = 'GET';
const PUT = 'PUT';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

const getToken = async () => {
	try {
		const token = await window.localStorage.getItem('token');
		if (token !== null) {
			// token previously stored
			return token;
		}
	} catch (e) {
		throw new Error('Token not found or could not be retrieved');
	}
};

const handleResponse = (res) => {
	let responseBody = null;
	const responseType = res.headers.get('Content-Type');

	if (responseType && responseType.includes('application/json')) {
		responseBody = res.json();
	} else {
		responseBody = res.text();
	}

	return responseBody.then((data) => {
		if (res.ok) {
			return Promise.resolve(data);
		} else {
			return Promise.reject(data);
		}
	});
};

const Request = async (route, method, priv = true, payload, signal = null) => {
	let config = {
		method: method,
		headers: {},
		signal,
	};
	let queryString = '';
	if ([POST, PUT, PATCH].includes(method)) {
		config = {
			...config,
			body: JSON.stringify(payload),
			headers: {
				'content-type': 'application/json',
			},
		};
	} else {
		if (payload) {
			queryString = '?' + PayloadToQueryString(payload);
		}
	}
	if (priv) {
		let token = await getToken();
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${token}`,
		};
	}
	let url = `${API_HOST}${route}${queryString}`;
	return fetch(url, config).then((res) => {
		return handleResponse(res);
	});
};

const Download = async (route, method, priv = true, payload) => {
	let config = {
		method: method,
		headers: {},
	};
	let queryString = '';
	if ([POST, PUT, PATCH].includes(method)) {
		config = {
			...config,
			body: JSON.stringify(payload),
			headers: {
				'content-type': 'application/json',
			},
		};
	} else {
		if (payload) {
			queryString = '?' + PayloadToQueryString(payload);
		}
	}
	let url = `${API_HOST}${route}${queryString}`;
	return fetch(url, config)
		.then(async (res) => {
			return {
				fileName: FilenameFromHeader(res.headers.get('Content-Disposition')),
				blob: await res.blob(),
			};
		})
		.then((res) => {
			let newBlob = new Blob([res.blob], { type: 'application/pdf' });
			let fileData = window.URL.createObjectURL(newBlob);
			let link = document.createElement('a');
			link.href = fileData;
			link.download = res.fileName;
			link.click();
			setTimeout(() => {
				window.URL.revokeObjectURL(fileData);
			}, 250);
		});
};

const FilenameFromHeader = (header) => {
	let fileName = 'downloaded.pdf';
	let values = header.split(';');
	for (let v in values) {
		let s = values[v].trim().split('=');
		if (s.length === 2 && s[0] === 'filename') {
			fileName = s[1];
		}
	}
	return fileName;
};

const PayloadToQueryString = (payload) => {
	let qsa = [];
	for (let i in payload) {
		if (payload[i] && payload[i] !== '' && payload[i] !== null) {
			qsa.push(`${i}=${payload[i]}`);
		}
	}
	return qsa.join('&');
};

const Upload = async (route, filepath, mimetype) => {
	let token = await getToken();
	let formData = new FormData();
	formData.append('file', {
		uri: filepath.uri,
		name: ParseFilename(filepath.uri),
		type: mimetype,
	});
	let config = {
		method: 'post',
		body: formData,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return fetch(`${API_HOST}${route}`, config).then((res) => {
		return handleResponse(res);
	});
};

const UploadSingle = async (route, name, file) => {
	let token = await getToken();
	let formData = new FormData();
	formData.append(name, file);
	let config = {
		method: 'post',
		body: formData,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return fetch(`${API_HOST}${route}`, config).then((res) => {
		return handleResponse(res);
	});
};

const ParseFilename = (filename) => {
	let chunks = filename.split('/');
	let cl = chunks.length;
	let extension = chunks[cl - 1].split('.');
	let fname = '';
	if (['jpg', 'jpeg', 'png', 'gif'].includes(extension[1])) {
		fname = chunks[cl - 1];
	} else {
		let ext = chunks[cl - 2].split('%2F')[1];
		if (['jpg', 'jpeg', 'png', 'gif'].includes(ext)) {
			fname = `${chunks[cl - 1]}.${ext === 'jpeg' ? 'jpg' : ext}`;
		} else {
			fname = `${chunks[cl - 1]}.jpg`;
		}
	}
	return fname;
};

const UploadMultiple = async (route, files = [], payload) => {
	let token = await getToken();
	let formData = new FormData();
	files.forEach((f) => {
		formData.append(f.name, f);
	});
	if (payload) {
		for (let p in payload) {
			formData.append(p, payload[p]);
		}
	}
	let config = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		body: formData,
	};
	return fetch(`${API_HOST}${route}`, config).then((res) => {
		return handleResponse(res);
	});
};

const OpenLink = (url) => {
	let link = document.createElement('a');
	link.href = `${API_HOST}${url}`;
	window.open(link, '_blank');
};

const HandleError = (error, msg) => {
	let err = '';
	switch (error.status) {
		case 400:
			err = `${INVALID_DATA_ERROR}: ${msg}`;
			break;
		case 401:
			err = `${UNAUTHORISED}: ${msg}`;
			break;
		case 403:
			err = `${FORBIDDEN_ERROR}: ${msg}`;
			break;
		case 404:
			err = `${NOT_FOUND}: ${msg}`;
			break;
		case 413:
			err = `${PAYLOAD_TOO_LARGE}: ${msg}`;
			break;
		case 500:
			err = `${SERVER_ERROR}: ${msg}`;
			break;
		default:
			err = msg;
	}
	console.log('ERROR_MESSAGE:', err);
	return err;
};

export default {
	Request,
	Upload,
	UploadSingle,
	HandleError,
	Download,
	UploadMultiple,
	OpenLink,

	POST,
	PUT,
	PATCH,
	GET,
	DELETE,
};
