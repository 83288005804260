import AuthTemplate from 'com/templates/AuthTemplate';
import AuthTemplateIPeer from 'com/templates/AuthTemplateIPeer';
import useDomain from 'services/hooks/useDomain';

const withDomainAuthTemplate = (WrapperComponentAzami, WrappedComponentIPeer) => {
	return (props) => {
		let domain = useDomain();

		switch (domain) {
			case process.env.REACT_APP_AZAMI_DOMAIN:
				return (
					<AuthTemplate>
						<WrapperComponentAzami {...props} />
					</AuthTemplate>
				);
			case process.env.REACT_APP_IPEER_DOMAIN:
				return (
					<AuthTemplateIPeer leftContainerSize={props.leftContainerSize} rightContainerSize={props.rightContainerSize}>
						<WrappedComponentIPeer {...props} />
					</AuthTemplateIPeer>
				);
			case process.env.REACT_APP_IPEER_STAGE_DOMAIN:
				return (
					<AuthTemplateIPeer leftContainerSize={props.leftContainerSize} rightContainerSize={props.rightContainerSize}>
						<WrappedComponentIPeer {...props} />
					</AuthTemplateIPeer>
				);
			default:
				return (
					<AuthTemplate>
						<WrapperComponentAzami {...props} />
					</AuthTemplate>
				);
		}
	};
};

export default withDomainAuthTemplate;
