// vendor imports
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
// services
import CustomerSupportService from 'services/rest/customer_support';
import EventLoggerEvents from 'services/rest/event_logger';
// util
import AppLink from 'com/util/AppLink';
// ui comps
import Switch from 'com/ui/Switch';
import Select from 'com/ui/Select';
import Textarea from 'com/ui/Textarea';
import Input from 'com/ui/Input';
// widgets
import Modal from 'com/widgets/Modal';
// services
import useNotification from 'services/hooks/use_notification';
// data
import { ISSUE_TYPES, ROLE_TYPES } from 'data/constants';
// icons
import ICON_AZAMI_SUPPORT from 'assets/images/icons/ico_azami_support.svg';
import ICON_AZAMI_SUPPORT_MAGENTA from 'assets/images/icons/ico_azami_support_magenta.svg';
// styles
import './style.css';

const CustomerSupport = () => {
	const [isHovered, setIsHovered] = useState(false);
	const [showCustomerSupportModal, setShowCustomerSupportModal] = useState(false);
	const user = useSelector((state) => state.auth.user);

	const openCustomerSupportModal = () => {
		setShowCustomerSupportModal(true);
		setIsHovered(false);
		EventLoggerEvents.EventLogger({ event: `Help Icon Clicked`, data: { user: user } });
	};
	const closeCustomerSupportModal = () => setShowCustomerSupportModal(false);

	return (
		<div className={`customer-support ${user.roleType === ROLE_TYPES.EXTERNAL && 'external'}`} onMouseLeave={() => setIsHovered(false)}>
			<img src={isHovered ? ICON_AZAMI_SUPPORT_MAGENTA : ICON_AZAMI_SUPPORT} className="customer-support__img" onMouseEnter={() => setIsHovered(true)} onClick={openCustomerSupportModal} />
			<div className={`customer-support__tooltip ${isHovered && 'hovered'}`} onClick={openCustomerSupportModal}>
				<div className="customer-support__tooltip-header">
					<img src={ICON_AZAMI_SUPPORT} className="customer-support__tooltip-header-img" />
					<h2 className="customer-support__tooltip-header-title">Platform Support</h2>
				</div>
				<div className="customer-support__tooltip-content">
					<span>Need help?</span>
					<p>Submit a support request here.</p>
				</div>
			</div>
			{showCustomerSupportModal && <CustomerSupportModal closeHandler={closeCustomerSupportModal} />}
		</div>
	);
};

const CustomerSupportModal = (props) => {
	const formDataInit = {
		name: '',
		email: '',
		issue_type: '',
		case_number: '',
		description: '',
		sent_from: '',
	};

	const errorsInit = {
		name: '',
		email: '',
		issue_type: '',
		description: '',
	};

	const errorMessages = {
		required: 'Required',
	};

	const auth = useSelector((state) => state.auth);
	const sendNotification = useNotification();
	const [useAccount, setUseAccount] = useState(true);
	const [formData, setFormData] = useState(formDataInit);
	const [errors, setErrors] = useState(errorsInit);
	const [loading, setLoading] = useState(false);
	const user = useSelector((state) => state.auth.user);

	const onChangeSwitch = () => setUseAccount(!useAccount);
	const handleOnChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});

		setErrors({
			...errors,
			[e.target.name]: '',
		});
	};

	const validate = () => {
		let isValid = true;
		setErrors(errorsInit);

		for (let d in formData) {
			if (d === 'case_number' || d === 'sent_from') continue;
			if (!formData[d]) {
				isValid = false;
				setErrors((prevState) => ({ ...prevState, [d]: errorMessages.required }));
			}
		}
		return isValid;
	};

	const showCaseNumberField = formData.issue_type !== '' && formData.issue_type !== 'TECHNICAL_SUPPORT' && formData.issue_type !== 'LOGIN_PASSWORD';

	const handleOnSubmit = async () => {
		if (!validate()) return;
		setLoading(true);
		EventLoggerEvents.EventLogger({ event: `Submitted Help Request`, data: { user: user, form: formData } });

		try {
			await CustomerSupportService.Create({
				...formData,
				sent_from: document.title.split(' - ')[1], // ignoring the "Azami Global" from the title
			});
			sendNotification({ type: 'success', title: 'Your request has been successfully submitted. We will respond to your support request as soon as possible.' });
			props.closeHandler();
		} catch (error) {
			console.log('error', error);
		} finally {
			setLoading(false);
		}
	};

	const customerSupportModalFooterActions = [
		{ primary: false, label: 'Cancel', action: props.closeHandler, theme: 'azami-ghost' },
		{ primary: true, label: 'Submit', action: handleOnSubmit, theme: 'azami-blue', showSpinner: loading },
	];

	useEffect(() => {
		if (auth.loggedIn && useAccount) {
			setFormData({
				...formData,
				name: `${auth.user.name}`,
				email: auth.user.email,
			});
		}
		if (!auth.loggedIn) setUseAccount(false);
		return () => {
			setFormData(formDataInit);
		};
	}, [useAccount]);

	return (
		<Modal title="Platform Support" closeHandler={props.closeHandler} footerActions={customerSupportModalFooterActions}>
			<div className="customer-support-modal">
				{auth.loggedIn && <Switch label="Use my account" theme="blurple" value={useAccount} onChange={onChangeSwitch} />}
				{!useAccount && (
					<div className="customer-support-modal__group">
						<Input label="Name" name="name" onChange={handleOnChange} value={formData.name} error={errors.name} />
						<Input label="Email" name="email" onChange={handleOnChange} value={formData.email} error={errors.email} />
					</div>
				)}
				Please describe the problem you are encountering and what action you were taking when the problem occured.
				<div className="customer-support-modal__group">
					<Select label="Issue Type" name="issue_type" options={ISSUE_TYPES} onChange={handleOnChange} value={formData.issue_type} customClassName="" error={errors.issue_type} />
					{showCaseNumberField && <Input label="Case Number (if relevant)" name="case_number" onChange={handleOnChange} value={formData.case_number} />}
				</div>
				<Textarea label="Description" name="description" onChange={handleOnChange} value={formData.description} customClassName="customer-support-modal__textarea" error={errors.description} />
				<span>
					Your data will be handled in accordance with our{' '}
					<AppLink type="" to={{ pathname: 'https://azamiglobal.com/privacy-policy/' }} target="_blank">
						privacy policy.
					</AppLink>{' '}
					We will respond to your support request as soon as possible.
				</span>
			</div>
		</Modal>
	);
};

export default CustomerSupport;
