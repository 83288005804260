//vendor imports
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Userpilot } from 'userpilot';
//redux
import { setIsUpdated } from 'redux/ducks/redirect';
import { clearUserData } from 'redux/ducks/auth';
//widgtes
import Modal from 'com/widgets/Modal';
//utils
import AppLink from 'com/util/AppLink';
import { RoleBox } from 'com/util/RoleBox';
//services
import Auth from 'services/rest/auth';
import { random } from 'services/strings';
import useClickOutside from 'services/hooks/useClickOutside';
//styles
import './style.css';
//icons
import ICON_AVATAR_PLACEHOLDER from 'assets/images/icons/ico_avatar_placeholder_blurple.svg';
import ICON_GEAR from 'assets/images/icons/ico_gear_blurple.svg';
import ICON_FIRM from 'assets/images/icons/ico_firm_blue.svg';
import ICON_EXIT from 'assets/images/icons/ico_exit_blurple.svg';
import ICON_IMPORT from 'assets/images/icons/ico_tool_blurple.svg';

const UserMenu = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [menuVisibility, setMenuVisibility] = useState(false);
	const userMenuBtn = useClickOutside(() => setMenuVisibility(false));
	const isUpdated = useSelector((state) => state.redirect.isUpdated);
	const [showModalLogOut, setShowModalLogOut] = useState(false);
	const [avatarUrl, setAvatarUrl] = useState(null);
	const user = useSelector((state) => state.auth.user);

	const logOutHandleConfirm = () => {
		logOut();
		dispatch(setIsUpdated(false));
	};

	const logOutHandleCancel = () => {
		setShowModalLogOut(false);
	};

	const logOutNavigationActions = [
		{ primary: false, label: 'Cancel', action: logOutHandleCancel, theme: 'azami-ghost' },
		{ primary: true, label: 'Confirm', action: logOutHandleConfirm, theme: 'azami-blue' },
	];

	const logOut = async () => {
		try {
			await Auth.Logout();
			window.localStorage.removeItem('token');
			window.localStorage.clear();
			// clear redux
			dispatch(clearUserData());
			// clear closed banners cookie (to be displayed again on login)
			document.cookie = 'closedBanners=[]; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
			// Destroy Userpilot session
			Userpilot.destroy();
			history.push('/');
		} catch (err) {
			console.log(err);
		}
	};

	const logOutNavigationConfirmation = () => {
		if (isUpdated) {
			setShowModalLogOut(true);
		} else {
			logOut();
		}
	};

	const onClickUserMenu = (e) => {
		setMenuVisibility(!menuVisibility);
	};

	const GetImageUrl = async (image) => {
		if (image) {
			let string = random(20);
			setAvatarUrl(`${process.env.REACT_APP_PUBLIC_ASSETS_URL}/${image}?${string}`);
		} else {
			setAvatarUrl(ICON_AVATAR_PLACEHOLDER);
		}
	};

	useEffect(() => {
		GetImageUrl(user.image);
	}, [user.image]);

	return (
		<>
			<button className="user-menu" ref={userMenuBtn}>
				{menuVisibility ?
					<div className="user-menu__items-container">
						<span className="user-menu__items-container__arrow"></span>
						<ul className="user-menu__items-container__items">
							<AppLink to="/user-management/me" checkRedirect={isUpdated} checkRedirectFrom={['firm-management']} className="user-menu__items-container__items-link">
								<li>
									<img src={ICON_GEAR} />
									User Details
								</li>
							</AppLink>
							<AppLink to={`/firm-management/${user.fid}`} checkRedirect={isUpdated} checkRedirectFrom={['user-management']} className="user-menu__items-container__items-link">
								<li>
									<img src={ICON_FIRM} />
									Firm Profile
								</li>
							</AppLink>
							<RoleBox roles={['saas_user']}>
								<AppLink
									to="/tools/client-onboarding"
									target="_blank"
									checkRedirect={isUpdated}
									checkRedirectFrom={['user-management', 'firm-management']}
									className="user-menu__items-container__items-link"
								>
									<li>
										<img src={ICON_IMPORT} />
										Import
									</li>
								</AppLink>
							</RoleBox>
							<li onMouseDown={(e) => e.preventDefault()} onClick={logOutNavigationConfirmation}>
								<img src={ICON_EXIT} />
								Log out
							</li>
						</ul>
					</div>
				:	null}
				<img src={avatarUrl} className="user-menu__avatar" alt="" onClick={onClickUserMenu} />
			</button>
			{showModalLogOut ?
				<Modal closeHandler={logOutHandleCancel} title="Navigation confirmation" footerActions={logOutNavigationActions}>
					Are you sure you want to discard all unsaved edits?
				</Modal>
			:	null}
		</>
	);
};

export default UserMenu;
