import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { OwnerContext } from 'util/ownerContext';

import './style.css';

const RadioButton = (props) => {
	let theme = props.theme || 'default';
	const userData = useSelector((state) => state.auth.user);
	const ownerData = useContext(OwnerContext);

	const checkRoles = (uData, roles) => {
		if (uData.role.includes('admin')) return false;
		if (props.disabled) return props.disabled;
		if (roles.includes('$user') && ownerData.userMatch) return false;
		if (roles.includes('$firm') && ownerData.firmMatch) return false;
		if (roles.includes('$clientOfFirm') && ownerData.clientOfFirmMatch) return false;
		if (roles.includes('$agentOfFirm') && ownerData.agentOfFirmMatch) return false;
		if (roles.length === 0) return false;
		let uRoles = uData.role.filter((r) => {
			return roles.includes(r);
		});
		return uRoles.length === 0;
	};

	let disabled = checkRoles(userData, props.forRoles) ? 'disabled' : '';
	let themeCheck = props.themeCheck ? props.themeCheck : '';

	return (
		<label className={`form-radio ${theme} ${props.customClassName}`}>
			<input className="form-radio__input" type="radio" onChange={props.onChange} value={props.value} name={props.name} checked={props.checked} disabled={disabled} />
			<span className={`form-radio__checkmark ${disabled} ${themeCheck}`}></span>
			<span className="form-radio__label">{props.label}</span>
		</label>
	);
};

RadioButton.propTypes = {
	theme: PropTypes.string,
	themeCheck: PropTypes.string,
	customClassName: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	checked: PropTypes.bool,
	name: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	forRoles: PropTypes.array,
};

RadioButton.defaultProps = {
	themeCheck: '',
	theme: 'default',
	customClassName: '',
	value: '',
	checked: false,
	name: '',
	label: '',
	onClick: () => {},
	disabled: false,
	forRoles: [],
};

export default RadioButton;
