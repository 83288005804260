import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// widgets
import DataTable from 'com/widgets/DataTable';
import CreateAgentModal from 'com/widgets/CreateAgentModal';
// ui comps
import Button from 'com/ui/Button';
import Input from 'com/ui/Input';
import Select from 'com/ui/Select';
import LoaderOverlay from 'com/ui/LoaderOverlay';
// widgets
import PricePoints from 'com/widgets/PricePoints';
import StarsRating from 'com/widgets/StarsRating';
// services
import Settings from 'services/rest/settings';
import useNotification from 'services/hooks/use_notification';
import FirmAgents from 'services/rest/firm_agents';
import EventLoggerEvents from 'services/rest/event_logger';
import { roundDecimals } from 'services/strings';
import useDebounce from 'services/hooks/useDebounce';
// util
import AppLink from 'com/util/AppLink';
// data
import { DEBOUNCE_DELAY_MS } from 'data/constants';
// icons
import ICON_UPLOAD from 'assets/images/icons/ico-upload.svg';

import ICON_BLOCK_ACTION from 'assets/images/icons/ico_block.svg';
import ICON_ACTIVE_ACTION from 'assets/images/icons/ico_eye_visible.svg';
import ICON_INACTIVE_ACTION from 'assets/images/icons/ico_eye_not_visible.svg';
import ICON_STAR_ACTION from 'assets/images/icons/ico_star.svg';

const FirmDetailsAgents = (props) => {
	const { id } = useParams();
	const sendNotification = useNotification();

	const filtersInit = {
		region: '',
		firm_name: '',
		firm_size_id: '',
		technology: '',
		service: '',
	};

	const user = useSelector((state) => state.auth.user);
	const [agents, setAgents] = useState([]);
	const [firmSizes, setFirmSizes] = useState([]);
	const [regions, setRegions] = useState([]);
	const [countries, setCountries] = useState([]);
	const [filters, setFilters] = useState(filtersInit);
	const [technologies, setTechnologies] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [services, setServices] = useState([]);
	const [isNewAgentModalOpen, setIsNewAgentModalOpen] = useState(false);

	const debouncedValue = useDebounce(filters, DEBOUNCE_DELAY_MS);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			await fetchAgents();
		})();
	}, [debouncedValue]);

	const fetchAgents = async () => {
		setShowLoader(true);
		try {
			const agents = await FirmAgents.GetAllByClientID(id, filters);
			setAgents(agents);
		} catch (error) {
			console.log('error', error);
		} finally {
			setShowLoader(false);
		}
	};

	const fetchData = async () => {
		try {
			const [regions, firmSizes, technologies, services] = await Promise.all([Settings.GetRegions(), Settings.GetFirmSizes(), Settings.GetTechnologies(), Settings.GetServices()]);

			setCountries([{ label: '', value: '' }, ...regions.filter((r) => r.is_country).map((r) => ({ label: r.name, value: r.code, id: r.id, is_country: r.is_country }))]);
			setRegions([{ label: 'All', value: '' }, ...regions.map((r) => ({ label: r.name, value: r.id }))]);
			setFirmSizes([
				{ label: 'All', value: '' },
				...firmSizes.map((fs) => {
					return { ...fs, label: fs.label, value: fs.id };
				}),
			]);
			setTechnologies([{ label: 'All', value: '' }, ...technologies.map((t) => ({ label: t.name, value: t.id }))]);
			setServices([{ label: 'All', value: '' }, ...services.map((s) => ({ label: s.name, value: s.id, id: s.id }))]);
		} catch (error) {
			console.log('error', error);
		}
	};

	const tableHeaders = [
		{ title: 'Region', field: 'region', type: 'string', sortable: false },
		{ title: 'Name', field: 'name', type: 'string', sortable: false },
		{ title: 'Dedicated Associate', field: 'dedicated_associate', type: 'string', sortable: false },
		{ title: 'Firm size', field: 'firm_size', type: 'string', sortable: false },
		// { title: 'Service & Technology', field: 'technology', type: 'string', sortable: false }, // keep it for future use.
		{ title: 'Service', field: 'service', type: '', sortable: false },
		{ title: 'Price', field: 'price', type: '', sortable: false },
		{ title: 'Reciprocity', field: 'reciprocity', type: 'string', sortable: false },
		{ title: '', field: 'action', type: 'string', sort: 'none', sortable: false },
	];

	const getFirmSizeLabel = (numOfAtt) => {
		for (let fs of firmSizes) {
			if (fs.min && numOfAtt >= fs.min && ((fs.max && numOfAtt <= fs.max) || !fs.max)) {
				return fs.label;
			}
		}
	};

	const filterChange = (e) => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value,
		});
	};

	const onChangeDefaultAssociateUser = async (id, uid) => {
		try {
			await FirmAgents.updateDefaultAssociateUserByID(id, uid);
			setAgents(agents.map((a) => (a.id === id ? { ...a, default_associate_user: uid } : a)));
			sendNotification({ type: 'success', title: 'Dedicated associate updated successfully' });
			EventLoggerEvents.EventLogger({ event: 'Manually Set Default Associate', data: { user: user } });
		} catch (error) {
			console.log('error', error);
		}
	};

	const getRegionsName = (regions) => {
		if (regions.length > 0) {
			return regions.map((r) => r.region_name);
		}
		return [];
	};

	const getAgentActions = (id) => {
		if (!agents) return [];

		let agent = agents.find((a) => a.id === id);
		let isBlockEmails = !!agent.is_block_emails;
		let isActive = !!agent.is_active;
		let isFavorite = !!agent.is_favorite;

		let dataset = { id };
		let roles = ['saas_user', 'user', 'member', 'member_assistant', 'candidate'];

		return [
			isBlockEmails ?
				{ label: 'Unblock Emails', action: unblockAgentEmail, dataset, ico: ICON_BLOCK_ACTION, roles }
			:	{ label: 'Block Emails', action: blockAgentEmail, dataset, ico: ICON_BLOCK_ACTION, roles },
			isActive ?
				!isFavorite && { label: 'Mark As Inactive', action: markAgentInactive, dataset, ico: ICON_INACTIVE_ACTION, roles }
			:	{ label: 'Mark As Active', action: markAgentActive, dataset, ico: ICON_ACTIVE_ACTION, roles },
			isFavorite ?
				{ label: 'Mark As Not Favorite', action: markAgentNotFavorite, dataset, ico: ICON_STAR_ACTION, roles }
			:	isActive && { label: 'Mark As Favorite', action: markAgentFavorite, dataset, ico: ICON_STAR_ACTION, roles },
		].filter(Boolean);
	};

	const blockAgentEmail = (e) => updateAgent(e, 'is_block_emails', 1, 'User marked Agent as having Blocked Emails');

	const unblockAgentEmail = (e) => updateAgent(e, 'is_block_emails', 0, 'User marked Agent as Unblocked Emails');

	const markAgentActive = (e) => updateAgent(e, 'is_active', 1, 'User marked Agent as Active');

	const markAgentInactive = (e) => updateAgent(e, 'is_active', 0, 'User marked Agent as Inactive');

	const markAgentFavorite = (e) => updateAgent(e, 'is_favorite', 1, 'User marked Agent as Favorite');

	const markAgentNotFavorite = (e) => updateAgent(e, 'is_favorite', 0, 'User marked Agent as Not Favorite');

	const updateAgent = async (e, field, value, event) => {
		let { id } = e.currentTarget.parentNode.dataset;
		let agent = agents.find((a) => a.id === id);
		let payload = { ...agent, [field]: value };

		try {
			await FirmAgents.updateAgent(id, payload);
			setAgents(agents.map((a) => (a.id === id ? { ...a, [field]: value } : a)));
			EventLoggerEvents.EventLogger({ event: event, data: { user: user } });
			sendNotification({ type: 'success', title: 'Agent Setting updated successfully' });
		} catch (error) {
			console.log('error', error);
		}
	};
	const onNewAgentModalOpen = () => setIsNewAgentModalOpen(true);

	const onNewAgentModalClose = () => setIsNewAgentModalOpen(false);

	return (
		<div className="firm-details__agents">
			<LoaderOverlay showLoader={showLoader} />
			<div className="firm-details__agents-header">
				<div className="firm-details__agents-header__filters">
					<Select name="region" label="Region" options={regions} value={filters.region} onChange={filterChange} />
					<Input name="firm_name" label="Name" value={filters.firm_name} onChange={filterChange} />
					<Select name="firm_size_id" label="Firm Size" options={firmSizes} onChange={filterChange} value={filters.firm_size_id} />
					<Select name="service" label="Service" options={services} onChange={filterChange} value={filters.service} />
					{/* we are keeping the code below */}
					{/* <Select name="technology" label="Technology" options={technologies} value={filters.technology} onChange={filterChange}/>  */}
				</div>
				<AppLink type="regular" to="/tools/client-onboarding" className="form-button azami-blue firm-details__agents-header-btn">
					<img src={ICON_UPLOAD} />
					Bulk Upload
				</AppLink>
			</div>
			<div className="firm-details__agents-body">
				<DataTable fixedHeader={true} columns={tableHeaders}>
					{Array.isArray(agents) &&
						agents.length > 0 &&
						agents.map((a, i) => {
							let mappedMembers = a.members ? [...a.members?.map((am) => ({ value: am.id, label: `${am.first_name} ${am.last_name}` }))] : [];
							return (
								<tr key={i} data-aid={a.associate_id} className={`${a.is_active ? '' : 'inactive'} ${a.is_favorite ? 'favorite' : ''}`}>
									<DataTable.RuleRegionCell countryCode={a?.regions[0]?.region_code} cellTitle={getRegionsName(a.regions).join(', ')} countryCount={a?.regions?.length - 1}>
										{a.regions[0]?.region_code}
									</DataTable.RuleRegionCell>
									<DataTable.PersonCell ico={a.associate_image}>
										<AppLink type="regular" to={`/firm-management/${a.associate_id}`} trackClick={true} event="Client Loads Their OON Assoc's Firm Profile">
											{a.associate_name}
										</AppLink>
									</DataTable.PersonCell>
									<DataTable.Cell>
										<Select
											name="default_associate_user"
											options={mappedMembers}
											onChange={(e) => onChangeDefaultAssociateUser(a.id, e.target.value)}
											value={a.default_associate_user}
										/>
									</DataTable.Cell>
									<DataTable.Cell>{getFirmSizeLabel(a.associate_number_of_attorneys)}</DataTable.Cell>
									{/* we are keeping the code below */}
									{/* <DataTable.Cell customClassName='agents-table-cell__technology-service'>
                                <div className='agents-table-cell__technology-service-info'>
                                    {a.services?.map((s, i) => <span className="name" key={i}>{s.service_name}</span>)}
                                </div>
                                <div className='table-cell-tags'>
                                    {a.technologies?.map((t, i) => <span key={i}>{t.name}</span>)}
                                </div>
                            </DataTable.Cell> */}
									<DataTable.Cell>
										<StarsRating rating={a.rating} />
									</DataTable.Cell>
									<DataTable.Cell>
										<PricePoints length={5} value={roundDecimals(a.points.value, 2)} threshold={a.points.threshold} />
									</DataTable.Cell>
									<DataTable.Cell>
										<div className="agents-table-cell__reciprocity" title="Number of Cases Sent/Number of Cases Received">
											{a.sent_cases || 0}:{a.received_cases || 0}
										</div>
									</DataTable.Cell>
									<DataTable.ActionsMenuCell actions={getAgentActions(a.id)}></DataTable.ActionsMenuCell>
								</tr>
							);
						})}
				</DataTable>
			</div>
			<Button className="firm-details__agents-footer-add-agent" onClick={onNewAgentModalOpen}>
				Add Agent
			</Button>
			{isNewAgentModalOpen && (
				<CreateAgentModal
					clientFirmId={id}
					closeHandler={onNewAgentModalClose}
					countries={countries}
					redirect={true}
					createAllRates={true}
					activeFirm={true}
					services={services}
					agents={agents}
				/>
			)}
		</div>
	);
};

export default FirmDetailsAgents;
