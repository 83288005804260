import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import DataTable from 'com/widgets/DataTable';
import Button from 'com/ui/Button';
import Checkbox from 'com/ui/Checkbox';
import { RoleBox } from 'com/util/RoleBox';

import { AppRoute } from 'com/util/AppRoute';
import AppDate from 'com/util/AppDate';

import Routes from 'data/routes';

const CaseDetailsUnInstructed = ({ regions, filterData, setFilterData, regionsForInstruct, setRegionsForInstruct, fetchData, unInstructableCase, setInstructionDeadlineModal }) => {
	const tableHeadersInit = [
		{
			title: <Checkbox type="checkbox" name="all" onClick={toggleRegionForInstruct} />,
			field: '',
			sort: 'none',
			sortable: false,
		},
		{ title: 'Region', field: 'region_name', sort: 'none', sortable: true },
		{ title: 'Client', field: 'client', sort: 'none', sortable: false },
		{ title: 'Ref.No', field: 'ref_number', sort: 'none', sortable: false },
		{ title: 'Deadline', field: 'due_date', sort: 'none', sortable: true },
	];
	const [tableHeaders, setTableHeaders] = useState([]);
	const { id } = useParams();
	const history = useHistory();

	function toggleRegionForInstruct(e) {
		if (e.target.name === 'all' && e.target.checked) {
			return setRegionsForInstruct([...regions.map((r) => r.id)]);
		}
		if (e.target.name === 'all' && !e.target.checked) {
			return setRegionsForInstruct([]);
		}

		const regionId = regions.find((r) => r.id === e.target.value).id;
		if (e.target.checked) {
			setRegionsForInstruct([...regionsForInstruct, regionId]);
		}
		if (!e.target.checked) {
			setRegionsForInstruct([...regionsForInstruct.filter((rid) => rid !== e.target.value)]);
		}
	}

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filterData.order_by.filter((r) => r.field === col).length === 0) {
						setFilterData({
							...filterData,
							order_by: [{ field: col, direction: h.sort }, ...filterData.order_by],
						});
					} else {
						setFilterData({
							...filterData,
							order_by: [{ field: col, direction: h.sort }, ...filterData.order_by.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
	};

	const openInstructModal = (e) => {
		if (unInstructableCase) {
			e.stopPropagation();
			setInstructionDeadlineModal(true);
		} else {
			let query = regionsForInstruct.length > 0 ? `?regions=${regionsForInstruct.join(',')}` : '';
			history.push(`/case-details/${id}/uninstructed/instruct${query}`);
		}
	};

	useEffect(() => {
		setTableHeaders(tableHeadersInit);
	}, []);

	useEffect(() => {
		//reset filter if set from another tab
		if (filterData.order_by.length > 0) {
			setFilterData({
				fileName: '',
				region: '',
				category: '',
				attached_by: '',
				order_by: [],
			});
		}
	}, []);

	return (
		<div className="case-details-not-instructed">
			<div className="case-details-not-instructed__header">
				<RoleBox roles={['user', 'member', 'member_assistant', 'candidate', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager']}>
					<Button onClick={() => {}} theme="azami-ghost" className="case-details-not-instructed__header__add-region__btn">
						Add Region
					</Button>
				</RoleBox>
				<Button onClick={openInstructModal} theme="azami-blue">
					Instruct
				</Button>
			</div>
			<div className="case-details-not-instructed__main-screen">
				<DataTable fixedHeader={true} columns={tableHeaders} customClassName="case-details-not-instructed__table" onColumnSort={columnSort}>
					{regions.length > 0 &&
						regions.map((r) => {
							return (
								<tr key={r.id} data-rid={r.id}>
									<DataTable.Cell>
										<Checkbox value={r.id} name={r.region_name} onClick={toggleRegionForInstruct} checked={regionsForInstruct.includes(r.id) ? true : false} />
									</DataTable.Cell>
									<DataTable.CountryCell code={r.region_code}>{r.region_name}</DataTable.CountryCell>
									<DataTable.Cell>{r.client_firm_name}</DataTable.Cell>
									<DataTable.Cell>{r.client_ref_number}</DataTable.Cell>
									<DataTable.Cell>
										<AppDate>{r.due_date}</AppDate>
									</DataTable.Cell>
								</tr>
							);
						})}
				</DataTable>
				<AppRoute route={Routes.CASE_INSTRUCT} fetchRegions={fetchData} exact />
			</div>
		</div>
	);
};

CaseDetailsUnInstructed.propTypes = {
	regions: PropTypes.array,
	filterData: PropTypes.object,
	setFilterData: PropTypes.func,
	regionsForInstruct: PropTypes.array,
	setRegionsForinstruct: PropTypes.func,
};

CaseDetailsUnInstructed.defaultProps = {
	regions: [],
	filterData: {},
	setFilterData: () => {},
	regionsForInstruct: [],
	setRegionsForinstruct: () => {},
};

export default CaseDetailsUnInstructed;
