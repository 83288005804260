import React, { useEffect } from 'react';

import { PAGE_TITLE } from 'data/constants';

const useTitle = (title) => {
	useEffect(() => {
		document.title = `${PAGE_TITLE.BASE} - ${title}`;
		return () => (document.title = PAGE_TITLE.BASE);
	}, [title]);
};

export default useTitle;
