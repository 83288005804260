import { useState, useEffect, useRef } from 'react';
import { useSelector, connect } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { setQuoteData, clearQuoteData, setQuoteDataFromObject } from 'redux/ducks/quote';
import { clearWipoData, setWipoData } from 'redux/ducks/wipo';
import AddRegionModal from 'com/widgets/AddRegionModal';
import Services from 'com/widgets/QuoteFields/Services';
import AdditionalInformation from 'com/widgets/QuoteFields/AdditionalInformation';
import Identifiers from 'com/widgets/QuoteFields/Identifiers';
import QuoteRegionPicker from 'com/widgets/QuoteFields/QuoteRegionPicker';
import Details from 'com/widgets/QuoteFields/Details';
import { RoleBox } from 'com/util/RoleBox';
import Button from 'com/ui/Button';
import InstructionDeadlineModal from 'com/widgets/InstructionDeadlineModal';
import QuoteDuplicateApplicationNumberModal from 'com/widgets/QuoteModals/QuoteDuplicateApplicationNumberModal';
import LoaderOverlay from 'com/ui/LoaderOverlay';

import { INTERNAL_ROLES, SERVICE_KEYS, ROLE_TYPES, EXTERNAL_ROLES, DOCUMENT_OBJECT_TYPE, APP_NUM_IGNORED_KEY_WORDS, PAGE_TITLE, QUOTE_EVENTS } from 'data/constants';
import Settings from 'services/rest/settings';
import Firms from 'services/rest/firms';
import Users from 'services/rest/users';
import Quotes from 'services/rest/quotes';
import Estimates from 'services/rest/estimates';
import QuotesRegions from 'services/rest/quotes-regions';
import Documents from 'services/rest/documents';
import Base from 'services/rest/base';
import Wipo from 'services/wipo/wipo';
import EventLoggerEvents from 'services/rest/event_logger';
import { formatEmailsStringToJSON, formatApplicationNumber } from 'services/strings';
import { validate, validateApplicationNumberFormat } from 'services/validators/quotes';
import { toENCADateString } from 'services/dates';
import Lists from 'com/pages/SystemSettingsEstimatorRules/lists';

import useNotification from 'services/hooks/use_notification';
import useTitle from 'services/hooks/useTitle';

import ICON_INFO from 'assets/images/icons/ico_toast_info_violet.svg';

import './style.css';

const QuotesDetailsBase = (props) => {
	const sendNotification = useNotification();
	const user = useSelector((state) => state.auth.user);
	const blockAddRegions = user.role.includes('saas_user');
	const duplicateQuoteInit = {
		application_number: '',
		case_number: '',
	};
	const { id } = useParams();
	const history = useHistory();
	const locationPartials = useLocation();
	const currentLocation = locationPartials.pathname.split('/')[2];
	const location = {
		ADD: 'add',
		INSTRUCT: 'instruct-without-pre-existing',
	};
	useTitle(PAGE_TITLE.EDIT_QUOTE);

	const sectionsInit = {
		servicesTechnologies: user.roleType === ROLE_TYPES.INTERNAL ? true : false,
		identifiers: false,
		regions: user.roleType === ROLE_TYPES.INTERNAL ? false : true,
		details: false,
		additionalInfo: false,
	};

	const filterData = {
		firm_id: '',
		order_by: [{ field: 'first_name', direction: 'up' }],
	};

	const [services, setServices] = useState([]);
	const [technologies, setTechnologies] = useState([]);
	const [regions, setRegions] = useState([]);
	const [firms, setFirms] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [clients, setClients] = useState([]);
	const [documents, setDocuments] = useState([]);
	const [sections, setSections] = useState(sectionsInit);
	const [errors, setErrors] = useState({});
	const [duplicatesModalOpen, setDuplicatesModalOpen] = useState(false);
	const [duplicateQuote, setDuplicateQuote] = useState(duplicateQuoteInit);
	const [chinaExtensionMessage, setChinaExtensionMessage] = useState(false);
	const [quoteRegions, setQuoteRegions] = useState([]);
	const [addRegionsModalOpen, setAddRegionsModalOpen] = useState(false);
	const [instructionDeadlineModal, setInstructionDeadlineModal] = useState(false);
	const [saveSpinner, setSaveSpinner] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				setShowLoader(true);
				props.clearQuoteData();
				props.clearWipoData();
				const [servicesData, technologiesData, firmsData, languagesData, regionsData] = await Promise.all([
					Settings.GetServices(),
					Settings.GetTechnologies(),
					Firms.GetAllRaw(),
					Settings.GetLanguages(),
					Settings.GetRegions(),
				]);
				setRegions(regionsData);
				setServices(servicesData);
				setTechnologies(technologiesData);
				setFirms([
					{ label: '', value: '' },
					...firmsData.map((f) => {
						return { label: f.name, value: f.id, notes: f.notes };
					}),
				]);

				if (languagesData) {
					setLanguages(
						languagesData.map((f) => {
							return { label: f.name, value: f.id, code: f.code };
						}),
					);
				}

				// dataSet
				if (id) {
					let quoteData = await fetchQuoteData(id);
					props.setQuoteDataFromObject(quoteData);
					if (quoteData && quoteData.client_firm_id) {
						let filter = {
							order_by: filterData.order_by.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(','),
							firm_id: quoteData.client_firm_id,
						};
						let clientsData = await Users.GetAll(filter);
						setClients([
							{ label: '', value: '' },
							...clientsData.data.map((c) => {
								return { label: `${c.first_name} ${c.last_name}`, value: c.id };
							}),
						]);
					}

					if (quoteData && quoteData.regions.length > 0) {
						setQuoteRegions(quoteData.regions);
					}

					if (quoteData && quoteData.oon_associate) {
						let lastEstimate = await Estimates.GetLastEstimateByQuoteId(quoteData.id);
						if (lastEstimate) {
							let associateData = await Estimates.GetOONForEstimate(lastEstimate.id);
							if (associateData.length > 0) {
								let data = [];
								associateData.map((a) => {
									if (a.associate_id !== '0') {
										data.push({
											region_id: a.region_id,
											associate_id: a.associate_id,
											official_fee: a.official_fee,
											professional_fee: a.professional_fee,
											translation_fee: a.translation_fee,
										});
									}
								});
								if (data.length > 0) {
									props.setQuoteData({ name: 'additional_region_info', value: data });
								}
							}
						}
					}
					setShowLoader(false);
					if (quoteData && quoteData.service_id && quoteData.application_number && servicesData && user.roleType === ROLE_TYPES.INTERNAL && languagesData) {
						let wipoData = await handleGetWipo(quoteData.application_number, quoteData.service_id, servicesData, languagesData);
						if (wipoData) {
							props.setWipoData({
								application_number: quoteData.application_number,
								wipo_data: wipoData,
							});
							props.setQuoteData({ name: 'description_images_size', value: wipoData.description_images_size });
						}
					}
				}
				setShowLoader(false);
			} catch (err) {
				setShowLoader(false);
				console.log(err);
			}
		})();
	}, []);

	const fetchQuoteData = async (id) => {
		let quoteData = await Quotes.GetOne(id);
		await getDocuments(id);
		if (quoteData) {
			if (quoteData.earliest_priority_date) {
				quoteData.earliest_priority_date = toENCADateString(quoteData.earliest_priority_date);
			}
			if (quoteData.intl_filing_day) {
				quoteData.intl_filing_day = toENCADateString(quoteData.intl_filing_day);
			}
			if (quoteData.intl_filing_date) {
				quoteData.intl_filing_date = toENCADateString(quoteData.intl_filing_date);
			}
			if (quoteData.draft) {
				quoteData.regions = quoteData.case_regions || [];
			}
		}
		return quoteData;
	};

	const generateClientSpecialInstructionNote = async () => {
		let clientData = await Users.GetOne(user.uid);
		let user_initials = `${clientData.first_name[0]}.${clientData.last_name[0]}.`;
		let note_header = '';
		let today_date = new Date();
		let date = today_date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
		if (user.roleType === ROLE_TYPES.EXTERNAL) note_header = 'Member Created\n';
		let note = `${note_header}Incomplete:\nSmall/Micro Entity: default\nRussia Unsearchable Claims:\n(${user_initials} - ${date})`;
		return note;
	};

	const processWipoPrewievData = (wipoData) => {
		if (wipoData.earliest_priority_date) {
			let [year, month, day] = wipoData.earliest_priority_date.split('-');
			wipoData.earliest_priority_date = `${month}/${day}/${year}`;
		}
		if (wipoData.intl_filing_date) {
			let [year, month, day] = wipoData.intl_filing_date.split('-');
			wipoData.intl_filing_date = `${month}/${day}/${year}`;
		}
		wipoData.location_of_search = wipoData.location_of_search || 'NO_REPORT';
		wipoData.location_of_search = Lists.calculationValueFromListValueLocationOfSearch.some((l) => l.value === wipoData.location_of_search) ? wipoData.location_of_search : 'OTHER';
		return wipoData;
	};

	// const technologyChange = (e) => {
	//     if (e.target.checked) {
	//         setData({
	//             ...data,
	//             application_technologies: [
	//                 ...data.application_technologies,
	//                 e.target.name
	//             ]
	//         });
	//     } else {
	//         setData({
	//             ...data,
	//             application_technologies: data.application_technologies.filter(ds => ds !== e.target.name)
	//         });
	//     }
	// };

	const getDocuments = async (id) => {
		let documentsData = await Documents.getByCase(id);
		documentsData = documentsData.map((d) => {
			d.is_uploaded = true;
			return d;
		});
		setDocuments(documentsData);
	};

	const regionPickerChange = (regions) => {
		props.setQuoteData({ name: 'regions', value: regions });
	};

	const regionModalChange = (e) => {
		props.setQuoteData({ name: 'additional_info', value: e.target.value });
	};

	const sectionClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setSections({
			servicesTechnologies: false,
			identifiers: false,
			regions: false,
			details: false,
			additionalInfo: false,
			[e.target.dataset.name]: !services[e.target.dataset.name],
		});
	};

	const generateInternalNotes = () => {
		let reg = `${props.quote.internal_quote_notes}\nMember added regions:`;
		for (let r of newRegions()) {
			let region = regions.find((s) => s.id == r);
			if (region) {
				reg += ` ${region.name} (${region.code})`;
			}
		}
		let date = new Date().toLocaleDateString('en-CA', { month: 'short', day: '2-digit', year: 'numeric' });
		reg += `\n(Member - ${date})`;
		return reg;
	};

	const update = () => {
		if (newRegions().length > 0) {
			setAddRegionsModalOpen(true);
		} else {
			if (saveSpinner) return;
			updateDetails();
		}
	};

	const updateDetails = async () => {
		if (user.roleType === ROLE_TYPES.EXTERNAL && newRegions().length === 0) return;
		setSaveSpinner(true);
		if (!validateQuote()) {
			setSaveSpinner(false);
			return;
		}
		let duplicate = await checkDuplicates(props.quote.service_id, props.quote.application_number, props.quote.client_firm_id);
		if (duplicate) {
			setErrors({
				...errors,
				application_number: 'Already Existing',
			});
			setSaveSpinner(false);
			return;
		}

		try {
			let temp_d = props.quote;
			if (user.roleType === ROLE_TYPES.EXTERNAL && newRegions().length > 0) {
				temp_d.internal_quote_notes = generateInternalNotes();
			}
			let oon_associate = props.quote.additional_region_info.some((r) => r.associate_id && r.associate_id !== '0');
			let res = await Quotes.UpdateByID(id, {
				...temp_d,
				client_contact_email_for_quotes: formatEmailsStringToJSON(temp_d.client_contact_email_for_quotes),
				client_contact_bcc: formatEmailsStringToJSON(temp_d.client_contact_bcc),
				oon_associate: oon_associate,
			});
			// remove documents if associated region is removed
			await removeDocuments();
			// upload new documents
			await uploadDocuments(id);
			await getDocuments(id);
			if (res) {
				if (user.roleType === ROLE_TYPES.EXTERNAL) {
					if (res.exchange_rate_date) {
						res.exchange_rate_date = toENCADateString(res.exchange_rate_date);
					}
					let estimate = await Estimates.CreateEstimate({
						quote: res,
					});

					if (estimate.attachment.s3key && estimate.attachment.filename) {
						Base.OpenLink(`/storage/document/${estimate.attachment.filename}?key=${estimate.attachment.s3key}&download_type=inline`);
					}
				}
				setSaveSpinner(false);
				if (res.id) {
					if (user.roleType === ROLE_TYPES.EXTERNAL) {
						history.push(`/quotes?case-number=${res.case_number}`);
					}
				}
			}
			setSaveSpinner(false);
		} catch (err) {
			console.log(err);
			setSaveSpinner(false);
		}
	};

	const removeDocuments = async () => {
		let data = documents.filter((d) => d.for_delete === true);
		if (data.length === 0) {
			return;
		}
		for (let d of data) {
			await Documents.deleteByID(d.id);
		}
	};

	const uploadDocuments = async (qid) => {
		let quoteRegions = await QuotesRegions.GetAllByQuoteId(qid);
		// filter documents that are only for update
		let documentsUpdate = documents.filter((d) => d.is_uploaded === true && d.for_update === true);
		// filter documents that are not already uploaded
		let documentsCreate = documents.filter((d) => d.is_uploaded === false);

		for (let d of documentsCreate) {
			// remap region id to quoteRegionID (needed for document object_id)
			const qr = quoteRegions.data.find((r) => r.region_id === d.region_id);

			// if region is removed but it has previous selected file for that region
			if (d.for_delete === true) {
				continue;
			}

			const payload = {
				object_type: d.object_type,
				object_id: d.object_type === DOCUMENT_OBJECT_TYPE.QUOTE_REGION || d.object_type === DOCUMENT_OBJECT_TYPE.CASE_REGION ? qr.id : qid,
				description: d.description,
				category: 'INITIAL_DOCUMENTS',
			};
			await Storage.DocumentUpload([d], payload);
		}
		for (let d of documentsUpdate) {
			await Documents.UpdateByID(d.id, d);
		}
	};

	const validateQuote = () => {
		let result = validate(props.quote, services, user.roleType === ROLE_TYPES.EXTERNAL, user.role[0]);
		if (!result.isValid) {
			setErrors(result.errorFields);
			sendNotification({ type: 'error', title: 'Changes not saved. Fix errors above.' });
			return false;
		}
		setErrors(result.errorFields);
		return true;
	};

	const closeAddRegionsModal = () => {
		setAddRegionsModalOpen(false);
	};

	// const chinaExtensionMessageClose = () => {
	//     setChinaExtensionMessage(false);
	// };

	const checkDuplicates = async (service_id, application_number, client_firm_id) => {
		// allow duplicates with defined keywords
		if (APP_NUM_IGNORED_KEY_WORDS.includes(props.quote.application_number.toUpperCase())) return;
		let qData = {
			application_number: application_number,
			service_id: service_id,
			client_firm_id: client_firm_id,
		};
		try {
			const q = await Quotes.GetByClientsFirm(qData);
			if (q) {
				// check for edit mode on existing quote
				if (id && props.quote.application_number === q.application_number && q.id === props.quote.id) return;
				setDuplicateQuote(q);
				duplicatesModalOpenHandler();
				return true;
			}
		} catch (err) {
			if (err === 'Not Found' && duplicateQuote.application_number) {
				setDuplicateQuote(duplicateQuoteInit);
				setDuplicatesModalOpen(false);
				return;
			}
			console.log(err);
		}
	};

	const duplicatesModalCloseHandler = () => {
		setDuplicatesModalOpen(false);
	};

	const duplicatesModalOpenHandler = () => {
		setDuplicatesModalOpen(true);
	};

	const goToDetails = () => {
		history.push(`/quotes/${duplicateQuote.id}`);
	};

	const duplicatesModalFooterActions = [
		{ primary: false, label: 'Continue', action: goToDetails, theme: 'azami-blue' },
		{ primary: true, label: 'Cancel', action: duplicatesModalCloseHandler, theme: 'azami-ghost' },
	];

	// const chinaExtensionMessageCancel = [
	//     { primary: true, label: 'Cancel', action: chinaExtensionMessageClose, theme: 'azami-ghost' }
	// ];

	const initialDocuments = () => {
		let initialDocumentsdocuments = [];
		initialDocumentsdocuments = documents.filter((d) => d.category === 'INITIAL_DOCUMENTS');
		return initialDocumentsdocuments;
	};

	const newRegions = () => {
		let newRegions = [];
		newRegions = props.quote.regions.filter((r) => !quoteRegions.includes(r));
		return newRegions;
	};

	const getServiceKey = (service_id) => {
		let service = services.find((s) => s.id === service_id);
		return service?.key;
	};

	const instructionDeadlineModalClose = () => {
		setInstructionDeadlineModal(false);
	};

	const instructionDeadlineModalCancel = [{ primary: true, label: 'Cancel', action: instructionDeadlineModalClose, theme: 'azami-ghost' }];

	const handleGetWipo = async (application_number, service_id, services, languages) => {
		if (Wipo.enableGetWipo(service_id, services)) {
			let service = services.find((s) => s.id === service_id);
			let app_number = application_number;
			if (service.key === SERVICE_KEYS.PCT) {
				app_number = formatApplicationNumber(app_number);
			}
			if (service.key === SERVICE_KEYS.UTILITY || service.key === SERVICE_KEYS.PARIS) {
				app_number = formatApplicationNumber(app_number);
				if (!validateApplicationNumberFormat(app_number)) {
					return false;
				}
			}
			let payload = Wipo.formatWipoRequestObj(app_number, props.quote.service_id);
			let result = await Settings.GetWipo(payload);
			if (result.succeed) {
				let res = result.result;
				let app_language = languages.find((l) => l.code === res.application_language);
				res.application_language = app_language.value;
				let location_of_search = res.location_of_search || 'NO_REPORT';
				res.location_of_search = Lists.calculationValueFromListValueLocationOfSearch.some((l) => l.value === location_of_search) ? location_of_search : 'OTHER';
				return res;
			}
		}
		return false;
	};

	return (
		<>
			<LoaderOverlay showLoader={showLoader} />
			<div className={'main-screen-section white no-padding quote-details'}>
				<div className="main-screen-section__details-content__body quote-details">
					<div className="quote-details-view_quote-info">
						{props.quote.additional_info ?
							<div className="quote-details-view_quote-info-container">
								<div className="quote-details-view_quote-info-icon">
									<img src={ICON_INFO} alt="" />
								</div>
								There are notes from the client in Additional Information
							</div>
						:	null}

						{documents && documents.length > 0 ?
							<div className="quote-details-view_quote-info-container">
								<div className="quote-details-view_quote-info-icon">
									<img src={ICON_INFO} alt="" />
								</div>
								There are attachments in Additional Information
							</div>
						:	null}
					</div>
					<>
						<RoleBox roles={INTERNAL_ROLES}>
							<details className="settings-colapsible" open={sections.servicesTechnologies}>
								<summary
									className={`settings-colapsible__header ${errors.service_id ? 'error' : ''} ${errors.recordal_type ? 'error' : ''} ${errors.application_technologies ? 'error' : ''} ${errors.priority_type ? 'error' : ''}`}
									onClick={sectionClick}
									data-name="servicesTechnologies"
								>
									Services &amp; Technologies
								</summary>
								<div className="settings-colapsible__body">
									{sections.servicesTechnologies ?
										<Services services={services} technologiesData={technologies} errors={errors} setErrors={setErrors} checkDuplicates={checkDuplicates} newQuote={false} />
									:	null}
								</div>
							</details>
						</RoleBox>
						<RoleBox roles={INTERNAL_ROLES}>
							<details className="settings-colapsible" open={sections.identifiers}>
								<summary
									className={`settings-colapsible__header ${errors.application_number ? 'error' : ''} ${errors.client_id ? 'error' : ''}`}
									onClick={sectionClick}
									data-name="identifiers"
								>
									Identifiers
								</summary>
								<div className="settings-colapsible__body">
									{sections.identifiers ?
										<Identifiers
											services={services}
											errors={errors}
											setErrors={setErrors}
											newQuote={false}
											languages={languages}
											firms={firms}
											clients={clients}
											setClients={setClients}
											checkDuplicates={checkDuplicates}
										/>
									:	null}
								</div>
							</details>
						</RoleBox>
						<details className="settings-colapsible" open={sections.regions}>
							<summary className={`settings-colapsible__header ${errors.regions ? 'error' : ''}`} onClick={sectionClick} data-name="regions">
								Regions{' '}
								{props.quote.regions && props.quote.regions.length > 0 ?
									<span>{props.quote.regions.length}</span>
								:	''}
							</summary>
							<div className="settings-colapsible__body">
								{sections.regions ?
									<QuoteRegionPicker
										regions={regions}
										services={services}
										errors={errors}
										languages={languages}
										selectedServiceKey={getServiceKey(props.quote.service_id)}
										addRegions={true}
										quoteRegions={quoteRegions}
										blockAddRegions={blockAddRegions}
										editQuote={true}
									/>
								:	null}
							</div>
						</details>
						<RoleBox roles={INTERNAL_ROLES}>
							<details className="settings-colapsible" open={sections.details}>
								<summary className={`settings-colapsible__header ${errors.quote_details_header_error ? 'error' : ''}`} onClick={sectionClick} data-name="details">
									Details
								</summary>
								<div className="settings-colapsible__body">
									{sections.details ?
										<Details services={services} languages={languages} regions={regions} errors={errors} newQuote={false} />
									:	null}
								</div>
							</details>
						</RoleBox>
						<details className="settings-colapsible" open={sections.additionalInfo}>
							<summary className={`settings-colapsible__header ${errors.agree_to_terms ? 'error' : ''}`} onClick={sectionClick} data-name="additionalInfo">
								Additional Info
							</summary>
							<div className="settings-colapsible__body">
								{sections.additionalInfo ?
									<AdditionalInformation
										uploadTable={false}
										instructWithoutQuote={props.quote.state === 'QUOTE' ? false : true}
										regions={regions}
										errors={errors}
										objectTypeInit={currentLocation === location.ADD ? DOCUMENT_OBJECT_TYPE.QUOTE : DOCUMENT_OBJECT_TYPE.CASE}
									/>
								:	null}
							</div>
						</details>
					</>
					<div className="main-screen-section__details-content__footer">
						<Button showSpinner={saveSpinner} onClick={update}>
							Save
						</Button>
					</div>
				</div>
			</div>
			{addRegionsModalOpen ?
				<AddRegionModal
					data={props.quote}
					documents={initialDocuments()}
					regions={regions}
					newRegions={newRegions()}
					closeHandler={closeAddRegionsModal}
					updateDetails={updateDetails}
					onChange={regionPickerChange}
					fieldChange={regionModalChange}
				/>
			:	null}

			{instructionDeadlineModal ?
				<InstructionDeadlineModal
					designService={getServiceKey(props.quote.service_id) === SERVICE_KEYS.DESIGN}
					footerActions={instructionDeadlineModalCancel}
					closeHandler={instructionDeadlineModalClose}
				/>
			:	null}
			{duplicatesModalOpen ?
				<QuoteDuplicateApplicationNumberModal duplicatesModalFooterActions={duplicatesModalFooterActions} duplicateQuote={duplicateQuote} />
			:	null}
			{/* {chinaExtensionMessage ?
                <Modal footerActions={chinaExtensionMessageCancel} title='China Extension Fee'  >
                    <div className='quote-details__china-extension-modal_content'>
                        <span>The Priority Date or International Filing Date has been modified.</span>
                        <span>Adjust the switch for the 2-month extension into China, if necessary.</span>
                    </div>
                </Modal> : null} */}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		quote: state.quote,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setQuoteData: (data) => {
			dispatch(setQuoteData(data));
		},
		setQuoteDataFromObject: (data) => {
			dispatch(setQuoteDataFromObject(data));
		},
		setWipoData: (data) => {
			dispatch(setWipoData(data));
		},
		clearQuoteData: () => {
			dispatch(clearQuoteData());
		},
		clearWipoData: () => {
			dispatch(clearWipoData());
		},
	};
};

const QuotesDetails = connect(mapStateToProps, mapDispatchToProps)(QuotesDetailsBase);

export default QuotesDetails;
