import Switch from 'com/ui/Switch';
import Button from 'com/ui/Button';

import './style.css';

const QuoteFooter = (props) => {
	return (
		<div className="main-screen-section__details-content__footer">
			{/* <div className="new-quote__footer-switch-wrapper">
                <Switch theme='blurple' label={'Expand all sections'} onChange={props.showAllSectionsAction} value={props.showAllSections} />
            </div> */}
			<Button theme="azami-light-violet" onClick={props.footerCancel} className="new-quote__footer__btn-margin">
				Cancel
			</Button>
			<Button theme="azami-light-violet" onClick={props.saveAsDraft} showSpinner={props.draftSpinner} className="new-quote__footer__btn-margin">
				Save As Draft
			</Button>
			<Button disabled={props.activeIndex != 1 && !props.showAllSections ? false : true} onClick={props.footerPrevious} theme="azami-light-violet" className="new-quote__footer__btn-margin">
				Previous
			</Button>
			<Button onClick={props.footerNext} showSpinner={props.requestSpinner} className="new-quote__footer__btn-margin">
				{props.footerBtnLabel}
			</Button>
			<Button disabled={props.activeIndex != 6 && !props.showAllSections ? true : false} showSpinner={props.requestSpinner} onClick={props.showInstructModal}>
				Instruct Selected Cases
			</Button>
		</div>
	);
};

export default QuoteFooter;
