import { useState, useEffect } from 'react';

const useDomain = () => {
	let [domain, setDomain] = useState('');

	useEffect(() => {
		setDomain(window.location.hostname);
	}, []);
	return domain;
};

export default useDomain;
