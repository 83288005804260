//System Imports
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
//UI Imports
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';
import Checkbox from 'com/ui/Checkbox';
import AppLink from 'com/util/AppLink';
import ErrorMessage from 'com/ui/ErrorMessage';
import BackButton from 'com/ui/BackButton';
//Template Imports
import AuthBox from 'com/templates/AuthBox';
//Service Imports
import { ChangePasswordSchema } from 'services/validators/changePassword';
//Data Imports
import Routes from 'data/routes';
//Style Imports
import './style.css';

const ChangePasswordAzami = (props) => {
	return (
		<div className="change-password-container">
			<AuthBox title="Change Password" titleColor="azami-blurple" icon="password" footer={<BackButton to="/">Back to login</BackButton>}>
				<Formik initialValues={props.initialState} validationSchema={ChangePasswordSchema} onSubmit={props.changePasswordSubmit} validateOnChange={false}>
					{({ values, handleChange, validateField, errors, status }) => {
						const validate = (e) => validateField(e.target.name);

						return (
							<Form>
								<span className="change-password-container-azami__text">Please enter your new password.</span>
								<Input name="password" value={values.password} onChange={handleChange} error={errors.password} label="Password" type="password" onBlur={validate} />
								<Input
									name="password_repeat"
									value={values.password_repeat}
									onChange={handleChange}
									error={errors.password_repeat}
									label="Retype Password"
									type="password"
									onBlur={validate}
								/>
								{!props.tc && (
									<Checkbox
										labelClassName="user-details-content__footer__checkbox-label"
										label={
											<>
												I Agree to the{' '}
												<AppLink type="nav" to={Routes.LEGAL_INFO_TERMS_AND_CONDITIONS.path} target="_blank">
													Terms &amp; Conditions
												</AppLink>{' '}
												and{' '}
												<AppLink type="nav" to={Routes.LEGAL_INFO_ENGAGEMENT_LETTER.path} target="_blank">
													Engagement Letter
												</AppLink>{' '}
												of Azami Global
											</>
										}
										name="tos_agreement"
										value={values.tos_agreement}
										onChange={handleChange}
										error={errors.tos_agreement}
										onBlur={validate}
									/>
								)}
								<div className="change-password-container-azami__actions">
									<Button type="submit" showSpinner={props.spinner}>
										Submit
									</Button>
								</div>
								{status && <ErrorMessage error={status} />}
							</Form>
						);
					}}
				</Formik>
			</AuthBox>
		</div>
	);
};

ChangePasswordAzami.propTypes = {
	initialState: PropTypes.object,
	changePasswordSubmit: PropTypes.func,
	spinner: PropTypes.bool,
	tc: PropTypes.string,
};

ChangePasswordAzami.defaultProps = {
	initialState: {},
	changePasswordSubmit: () => {},
	spinner: false,
	tc: '',
};

export default ChangePasswordAzami;
