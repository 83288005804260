import Ajax from './base';

const GetAll = (payload) => {
	return Ajax.Request('/users', Ajax.GET, true, payload);
};

const GetAllRaw = (payload) => {
	return Ajax.Request('/users/raw', Ajax.GET, true, payload);
};

const Create = (payload) => {
	return Ajax.Request('/users', Ajax.POST, true, payload);
};

const GetOne = (id) => {
	return Ajax.Request(`/users/${id}`, Ajax.GET, true);
};

const Update = (id, payload) => {
	return Ajax.Request(`/users/${id}`, Ajax.PUT, true, payload);
};

const Activate = (id) => {
	const payload = {
		active: true,
	};
	return Ajax.Request(`/users/${id}/active`, Ajax.PATCH, true, payload);
};

const AddExpertise = (uid, eid) => {
	const payload = {
		technology_id: eid,
		user_id: uid,
	};
	return Ajax.Request(`/users/${uid}/expertise`, Ajax.POST, true, payload);
};

const RemoveExpertise = (uid, eid) => {
	return Ajax.Request(`/users/${uid}/expertise/${eid}`, Ajax.DELETE, true);
};

const UpdateExpertise = (uid, payload) => {
	return Ajax.Request(`/users/${uid}/expertise`, Ajax.PUT, true, payload);
};

const UpdateRegions = (uid, payload) => {
	return Ajax.Request(`/users/${uid}/regions`, Ajax.PUT, true, payload);
};

const UpdateRates = (uid, payload) => {
	return Ajax.Request(`/users/${uid}/rates`, Ajax.PUT, true, payload);
};

const GetPoints = (uid, queryStringData) => {
	return Ajax.Request(`/reciprocity/transactions`, Ajax.GET, false, { user_id: uid, ...queryStringData });
};

const ApplyAdjustedPoints = (uid, payload) => {
	return Ajax.Request(`/reciprocity/transactions/${uid}/points`, Ajax.POST, false, payload);
};

const UpdatePreferredAssociates = (uid, payload) => {
	return Ajax.Request(`/users/${uid}/preferred_associates`, Ajax.PUT, true, payload);
};

const UpdateBlacklistedAssociates = (uid, payload) => {
	return Ajax.Request(`/users/${uid}/blacklisted_associates`, Ajax.PUT, true, payload);
};

const NewAgentUserWelcome = (agent_uid, payload) => {
	return Ajax.Request(`/users/${agent_uid}/emails/new_agent_user_welcome`, Ajax.POST, true, payload);
};

export default {
	GetAll,
	GetAllRaw,
	Create,
	GetOne,
	Update,
	Activate,
	AddExpertise,
	RemoveExpertise,
	UpdateExpertise,
	UpdateRegions,
	UpdateRates,
	GetPoints,
	ApplyAdjustedPoints,
	UpdatePreferredAssociates,
	NewAgentUserWelcome,
	UpdateBlacklistedAssociates,
};
