import React from 'react';
import { connect } from 'react-redux';

import { getError } from 'util/errors';
import { setLoggedIn, setUserData } from 'redux/ducks/auth';
import Auth from 'services/rest/auth';
import EventLogger from 'services/rest/event_logger';
import jwt from 'services/jwt';

import withDomainAuthTemplate from 'com/hoc/withDomainAuthTemplate';
import LoginAzami from './LoginAzami';
import LoginIPeer from './LoginIPeer';

import { PAGE_TITLE } from 'data/constants';

import useTitle from 'services/hooks/useTitle';

import './style.css';

const LoginTemplate = withDomainAuthTemplate(LoginAzami, LoginIPeer);

const LoginBase = (props) => {
	useTitle(PAGE_TITLE.LOGIN);

	const initialState = {
		email: '',
		password: '',
	};

	const handleSubmit = async (fields, { setStatus }) => {
		try {
			let grctoken = await recaptchaToken();
			let res = await Auth.Login({ username: fields.email, password: fields.password, grct: grctoken });
			window.localStorage.setItem('token', res.token);
			const user = jwt.parseJWT(res.token);
			props.setUserData(user);
			props.setLoggedIn(true);
			EventLogger.EventLogger({ event: `Logged In`, data: { user: user } });
		} catch (err) {
			console.log(err);
			setStatus(getError(err.code));
		}
	};

	const recaptchaToken = async () => {
		return new Promise((success, fail) => {
			if (!window.grecaptcha) return success('');
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'submit' }).then((token) => {
					success(token);
				});
			});
		});
	};

	return <LoginTemplate initialState={initialState} handleSubmit={handleSubmit} />;
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedIn: (loggedIn) => {
			dispatch(setLoggedIn(loggedIn));
		},
		setUserData: (user) => {
			dispatch(setUserData(user));
		},
	};
};

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginBase);

export default Login;
