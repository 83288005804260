import { object, string, ref, boolean } from 'yup';
import { messages } from 'util/validations';

export const RegisterSchema = object().shape({
	// firm_registered: string(),
	title: string().required(messages.required.title),
	first_name: string().required(messages.required.first_name),
	last_name: string().required(messages.required.last_name),
	email: string().email(messages.invalid.email).required(messages.required.email),
	phone: string().required(messages.required.phone),
	country: string().required(messages.required.country),
	password: string().required(messages.required.password),
	password_repeat: string().oneOf([ref('password'), null], messages.required.password_repeat),
	// account_executive: string(),
	firm_code: string().when('firm_registered', { is: '1', then: string().required(messages.required.firm_code) }),
	firm_name: string().when('firm_registered', { is: '0', then: string().required(messages.required.firm_name) }),
	city: string().when('firm_registered', { is: '0', then: string().required(messages.required.city) }),
	address_1: string().when('firm_registered', { is: '0', then: string().required(messages.required.address_1) }),
	address2: string(),
	state: string().when('firm_registered', { is: '0', then: string() }),
	postal_code: string().when('firm_registered', { is: '0', then: string().required(messages.required.postal_code) }),
	firm_country: string().when('firm_registered', { is: '0', then: string().required(messages.required.firm_country) }),
	// address_2: string().when('firm_registered', { is: '0', then: string().required(messages.required.address_2) }),
	// pricing_level: string().when('firm_registered', { is: '0', then: string().required(messages.required.pricing_level) }),
	// pricing_level_ep: string().when('firm_registered', { is: '0', then: string().required(messages.required.pricing_level_ep) })
	tos_agreement: boolean().required(messages.required.tos_agreement).oneOf([true], messages.required.tos_agreement),
});
