//System Imports
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
//UTIL imports
import NotificationLayer from 'com/util/NotificationLayer';
//ICON imports
import IPEER_LOGO_WHITE from 'assets/images/ipeer_logo_white.svg';
//Style imports
import './style.css';

const AuthTemplateIPeer = (props) => {
	const history = useHistory();
	const animatePages = ['/register'];
	let animate = animatePages.includes(history.location.pathname) || (history.location.state && history.location.state.fromRegisterToLogin === true ? true : false);

	return (
		<>
			<div className="auth-template-ipeer">
				<div className={`auth-template-ipeer__welcome-container ${animate && 'animate'} ${props.leftContainerSize ? props.leftContainerSize : 'regular'}`}>
					<img src={IPEER_LOGO_WHITE} alt="logo" />
					<h1 className="auth-template-ipeer__welcome-container-header">
						<span>Welcome</span>&nbsp;
						<span>to iPeer.</span>
					</h1>
					<p className="auth-template-ipeer__welcome-container-message">
						Our mission is to empower IP professionals to collaborate with their global agents and partners in a way that strengthens their business performance. iPeer provides solutions
						that lets you make data-driven decisions when managing and instructing work to your global partners. iPeer is free to use, with options for additional access to powerful
						premium tools and analysis.
					</p>
				</div>
				<div className={`auth-template-ipeer__content ${animate && 'animate'} ${props.rightContainerSize ? props.rightContainerSize : 'regular'}`}>{props.children}</div>
			</div>
			<NotificationLayer />
		</>
	);
};

AuthTemplateIPeer.propTypes = {
	leftContainerSize: PropTypes.oneOf(['small', 'regular', 'big']),
	rightContainerSize: PropTypes.oneOf(['small', 'regular', 'big']),
};

AuthTemplateIPeer.defaultProps = {
	leftContainerSize: 'regular',
	rightContainerSize: 'regular',
};

export default AuthTemplateIPeer;
