import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AppTemplate from 'com/templates/ApplicationTemplate';
import DataTable, { Paging } from 'com/widgets/DataTable';
import Modal from 'com/widgets/Modal';

import Select from 'com/ui/Select';
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';
import LoaderOverlay from 'com/ui/LoaderOverlay';

import { PAGE_TITLE } from 'data/constants';

import Users from 'services/rest/users';
import Firms from 'services/rest/firms';
import Settings from 'services/rest/settings';

import AppLink from 'com/util/AppLink';

import useTitle from 'services/hooks/useTitle';
import useFilterStorage from 'services/hooks/useFilterStorage';

import './style.css';

import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';
import ICON_USER_GRANT from 'assets/images/icons/ico_user_management.svg';

const UserManagementList = () => {
	useTitle(PAGE_TITLE.USER_MANAGEMENT);

	const [firms, setFirms] = useState([]);
	const [roles, setRoles] = useState([]);
	const [regions, setRegions] = useState([]);
	const [affiliations, setAffiliations] = useState([]);
	const [users, setUsers] = useState([]);
	const [tableHeaders, setTableHeaders] = useState([]);
	const [showActivationModal, setShowActivationModal] = useState(false);
	const [activatedUser, setActivatedUser] = useState({ full_name: '', firm: '', id: '' });
	const [formData, setFormData] = useState({ name: '', email: '', role: '', firm_id: '', region_id: '', affiliation_id: '', orderBy: [] });
	const [paging, setPaging] = useState({ total_rows: 0, page: 1, limit: Paging.PAGE_SIZE[0].value });
	const [pageChange, setPageChange] = useState(1);
	const [showLoader, setShowLoader] = useState(false);
	const history = useHistory();
	const { getFilterValue, setFilterValue } = useFilterStorage();

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		getUsers();
	}, [pageChange]);

	const fetchData = async () => {
		try {
			setTableHeaders([
				{ title: 'Name', field: 'first_name', type: 'string', sort: 'none', sortable: true },
				{ title: 'Email', field: 'email', type: 'string', sort: 'none', sortable: true },
				{ title: 'Firm', field: 'firm', type: 'string', sort: 'none', sortable: true },
				{ title: 'Firm Code', field: 'firm_code', type: 'string', sort: 'none', sortable: false },
				{ title: 'Activation', field: 'activation', type: 'string', sort: 'none', sortable: false },
			]);

			const [firms, roles, regions, affiliations] = await Promise.all([Firms.GetAllRaw(), Settings.GetUserRoles(), Settings.GetRegions(), Settings.GetAffiliateGroups()]);

			setFirms([
				{ label: 'All', value: '' },
				...firms.map((d) => {
					return { label: d.name, value: d.id };
				}),
			]);

			setRoles([
				{ label: 'All', value: '' },
				...roles.map((d) => {
					return { label: d.title, value: d.id };
				}),
			]);

			setRegions([
				{ label: 'All', value: '' },
				...regions.map((d) => {
					return { label: d.name, value: d.id };
				}),
			]);

			setAffiliations([
				{ label: 'All', value: '' },
				...affiliations.map((d) => {
					return { label: d.name, value: d.id };
				}),
			]);
		} catch (err) {
			console.log(err);
		}
	};

	const filterChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const filterSubmit = async () => {
		setPaging({ ...paging, total_rows: 0, page: 1 });
		setPageChange(pageChange + 1);
		setFilterValue(formData);
	};

	const clearForm = () => {
		setFormData({ ...formData, name: '', email: '', role: '', firm_id: '', region_id: '', affiliation_id: '' });
		filterSubmit();
		setFilterValue(null);
	};

	const getUsers = async () => {
		try {
			let f = getFilterValue();
			if (f) setFormData(f);
			setShowLoader(true);
			let filters = {
				...(f ? f : formData),
				order_by: formData.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(','),
				page: paging.page,
				limit: paging.limit,
			};
			let u = await Users.GetAll(filters);
			setUsers(u.data);
			setPaging({ ...paging, total_rows: u.total_rows });
			setShowLoader(false);
		} catch (err) {
			setShowLoader(false);
		}
	};

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (formData.orderBy.filter((r) => r.field === col).length === 0) {
						setFormData({
							...formData,
							orderBy: [{ field: col, direction: h.sort }, ...formData.orderBy],
						});
					} else {
						setFormData({
							...formData,
							orderBy: [{ field: col, direction: h.sort }, ...formData.orderBy.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
		setPageChange(pageChange + 1);
	};

	const pagingChange = async (p, l) => {
		setPaging({ ...paging, page: p, limit: l });
		setPageChange(pageChange + 1);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	// POPUP

	const activatePopupHandler = (el) => {
		el.stopPropagation();
		let u = users.filter((u) => u.id === el.target.closest('tr').dataset.uid)[0];
		setActivatedUser({ full_name: `${u.first_name} ${u.last_name}`, firm: u.firm, id: u.id });
		setShowActivationModal(true);
	};

	const closePopupHandler = () => {
		setShowActivationModal(false);
	};

	const activateUser = async () => {
		try {
			await Users.Activate(activatedUser.id);
			setShowActivationModal(false);
			setUsers(
				users.map((u) => {
					if (u.id === activatedUser.id) {
						u.is_active = true;
					}
					return u;
				}),
			);
		} catch (e) {
			console.log(e);
		}
	};

	const popupFooterActions = [
		{ primary: false, label: 'Close', action: closePopupHandler, theme: 'azami-ghost' },
		{ primary: true, label: 'Activate', action: activateUser, theme: 'azami-blue' },
	];

	const goToUser = (e) => {
		history.push(`/user-management/${e.target.closest('tr').dataset.uid}`);
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			filterSubmit();
		}
	};

	return (
		<AppTemplate title="User Management" headerIcon="user_management">
			<AppTemplate.Sidebar>
				<div className="user-management-sidebar">
					<h4 className="user-management-sidebar__header">
						<img src={ICON_FUNNEL} alt="" />
						filters
					</h4>
					<Input name="name" label="Name" theme="dark" onChange={filterChange} value={formData.name} onKeyDown={handleKeyDown} />
					<Input name="email" label="Email address" theme="dark" onChange={filterChange} value={formData.email} onKeyDown={handleKeyDown} />
					<Select name="role" options={roles} label="Role" theme="dark" onChange={filterChange} value={formData.role} />
					<Select name="firm_id" options={firms} label="Firm" theme="dark" onChange={filterChange} value={formData.firm_id} />
					<Select name="region_id" options={regions} label="Region" theme="dark" onChange={filterChange} value={formData.region_id} />
					<Select name="affiliation_id" options={affiliations} label="Affiliation" theme="dark" onChange={filterChange} value={formData.affiliation_id} />
					<div className="user-management-sidebar_actions sidebar_actions">
						<Button onClick={filterSubmit}>Filter Users</Button>
						<Button theme="ghost" onClick={clearForm}>
							Clear
						</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Header>
				<AppLink type="nav" to={`/user-management/add`} activeClassName="active" className="form-button light" exact={true}>
					Create User
				</AppLink>
			</AppTemplate.Header>
			<AppTemplate.Content>
				<div className="main-screen-section white">
					<LoaderOverlay showLoader={showLoader} />
					<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort} customClassName="user-management__data-table">
						{users.map((u, i) => {
							return (
								<tr key={u.id} onClick={goToUser} data-uid={u.id}>
									<DataTable.PersonCell subtext={u.role} avatar="">
										{u.first_name} {u.last_name}
									</DataTable.PersonCell>
									<DataTable.Cell>{u.email}</DataTable.Cell>
									<DataTable.Cell>{u.firm}</DataTable.Cell>
									<DataTable.MarkerCell>{u.firm_code}</DataTable.MarkerCell>
									<DataTable.Cell>
										{!u.is_active && (
											<Button onClick={activatePopupHandler} data-uid={u.id}>
												Activate
											</Button>
										)}
									</DataTable.Cell>
								</tr>
							);
						})}
					</DataTable>
					<Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange} />
				</div>
				{showActivationModal && (
					<Modal title="User Activation" icon={ICON_USER_GRANT} closeHandler={closePopupHandler} footerActions={popupFooterActions}>
						Click "Activate" in order to grant <strong>{activatedUser.full_name}</strong> from <strong>{activatedUser.firm}</strong> access to the Azami Global portal.
					</Modal>
				)}
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default UserManagementList;
