import Ajax from './base';

const GetAllByClientID = (cid, payload) => {
	return Ajax.Request(`/firms/agents/clients/${cid}`, Ajax.GET, true, payload);
};

const GetAllByAssociateID = (aid, payload) => {
	return Ajax.Request(`/firms/agents/associates/${aid}`, Ajax.GET, true, payload);
};

const LinkAgent = (payload) => {
	return Ajax.Request(`/firms/agents`, Ajax.POST, true, payload);
};

const UpdateByClientID = (cid, payload) => {
	return Ajax.Request(`/firms/agents/clients/${cid}`, Ajax.PUT, true, payload);
};

const updateDefaultAssociateUserByID = (id, uid) => {
	return Ajax.Request(`/firms/agents/${id}/default-associate-user`, Ajax.PATCH, true, { default_associate_user: uid });
};

const updateRatingByHash = (payload) => {
	return Ajax.Request(`/firms/agents/rating/${payload.hash}/value`, Ajax.PATCH, true, { value: payload.value });
};

const updateAgent = (id, payload) => {
	return Ajax.Request(`/firms/agents/${id}`, Ajax.PATCH, true, payload);
};

export default {
	GetAllByClientID,
	GetAllByAssociateID,
	LinkAgent,
	UpdateByClientID,
	updateDefaultAssociateUserByID,
	updateRatingByHash,
	updateAgent,
};
