import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BI from 'services/rest/bi';

const RegionDetails = (props) => {
	const user = useSelector((state) => state.auth.user);
	const [activeTab, setActiveTab] = useState('agents');
	const [agents, setAgents] = useState([]);
	const [receivedCases, setReceivedCases] = useState([]);
	const [sentCases, setSentCases] = useState([]);
	const [region, setRegion] = useState({});

	useEffect(() => {
		if (props.selectedRegion) {
			setAgents([]);
			setReceivedCases([]);
			setSentCases([]);
			setRegion({});
			Promise.all([
				BI.GetRegionAgentsData(user.fid, props.selectedRegion),
				BI.GetRegionReceivedCasesData(user.fid, props.selectedRegion),
				BI.GetRegionSentCasesData(user.fid, props.selectedRegion),
				BI.GetRegionData(props.selectedRegion),
			])
				.then((values) => {
					setAgents(values[0]);
					setReceivedCases(values[1]);
					setSentCases(values[2]);
					setRegion(values[3]);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [props.selectedRegion]);

	const onTabClick = (e) => {
		setActiveTab(e.target.dataset.tab);
	};

	const closePanel = () => {
		setActiveTab('agents');
		props.closePanel();
	};

	return (
		<>
			{props.selectedRegion && (
				<div className="map__details-panel">
					<div className="map__details-panel__header">
						<span className="map__details-panel__header__title">Region Details: {region.name}</span>
						<button onClick={closePanel} className="map__details-panel__header__close">
							&times;
						</button>
					</div>
					<div className="map__details-panel__tabs">
						<ul>
							<li onClick={onTabClick} data-tab="agents" className={activeTab === 'agents' ? 'active' : ''}>
								Agents
							</li>
							<li onClick={onTabClick} data-tab="received" className={activeTab === 'received' ? 'active' : ''}>
								Received
							</li>
							<li onClick={onTabClick} data-tab="sent" className={activeTab === 'sent' ? 'active' : ''}>
								Sent
							</li>
							{/* <li onClick={onTabClick} data-tab="overview" className={activeTab === 'overview' ? 'active' : ''}>Overview</li> */}
						</ul>
					</div>
					<div className="map__details-panel__content">
						<div className={`map__details-panel__content-panel ${activeTab === 'agents' ? 'active' : ''}`}>
							<AgentsList agents={agents} />
						</div>
						<div className={`map__details-panel__content-panel ${activeTab === 'received' ? 'active' : ''}`}>
							<ReceivedCasesList cases={receivedCases} />
						</div>
						<div className={`map__details-panel__content-panel ${activeTab === 'sent' ? 'active' : ''}`}>
							<SentCasesList cases={sentCases} />
						</div>
						<div className={`map__details-panel__content-panel ${activeTab === 'overview' ? 'active' : ''}`}>{/* <Overview content={''}/> */}</div>
					</div>
				</div>
			)}
		</>
	);
};

const AgentsList = (props) => {
	return (
		<ul className="map__details-panel__content-panel__case-list">
			{props.agents.map((agent) => (
				<li key={agent.id}>
					<a href={`/firm-management/${agent.firm_id}`}>
						{agent.associate_name} ({agent.firm_name})
					</a>
				</li>
			))}
		</ul>
	);
};

const ReceivedCasesList = (props) => {
	return (
		<>
			<ul className="map__details-panel__content-panel__case-list">
				{props.cases.map((c) => (
					<li key={c.id}>
						<a target="_blank" href={`/case-details/${c.case_id}`}>
							{c.case_number} ({c.status})
						</a>
					</li>
				))}
			</ul>
			{/* <button className="map__details-panel__content-panel__load-more">Load more</button> */}
		</>
	);
};

const SentCasesList = (props) => {
	return (
		<>
			<ul className="map__details-panel__content-panel__case-list">
				{props.cases.map((c) => (
					<li key={c.id}>
						<a target="_blank" href={`/case-details/${c.case_id}`}>
							{c.case_number} ({c.status})
						</a>
					</li>
				))}
			</ul>
			{/* <button className="map__details-panel__content-panel__load-more">Load more</button> */}
		</>
	);
};

const Overview = (props) => {
	return <div>{props.content}</div>;
};

export default RegionDetails;
