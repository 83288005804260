import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Modal from 'com/widgets/Modal';
import DataTable from 'com/widgets/DataTable';
import PricingLevelPreview from 'com/widgets/PricingLevelPreview';

import Input from 'com/ui/Input';
import Textarea from 'com/ui/Textarea';
import Button from 'com/ui/Button';
import RadioButton from 'com/ui/RadioButton';
import Select from 'com/ui/Select';
import Switch from 'com/ui/Switch';
import LetterBadge from 'com/ui/LetterBadge';
import TagSelect from 'com/ui/TagSelect';

import Settings from 'services/rest/settings';
import Estimates from 'services/rest/estimates';
import PricingLevel from 'services/rest/pricing_levels';
import { validateRule } from 'services/validators/rules';
import useDebounce from 'services/hooks/useDebounce';
import AppDate from 'com/util/AppDate';

import Lists from 'com/pages/SystemSettingsEstimatorRules/lists.js';
import { CALCULATION_TYPE, RULE_TYPE } from 'com/pages/SystemSettingsEstimatorRules/SystemSettingsEstimatorRulesDetails';

import { DEBOUNCE_DELAY_MS } from 'data/constants';

import './style.css';

const CustomizeEstimateRule = (props) => {
	const { id } = useParams();

	const feesInit = [{ level_id: '', value: 10, range_from: 0, range_to: 0, group: 0 }];
	const feesInitByAmount = [{ level_id: '', value: 0, range_from: 0, range_to: 0, group: 0, range_error: '' }];
	const feesInitAbsoluteValue = [{ level_id: '', value: 0, range_from: 0, range_to: 0, group: 0 }];

	let dataInit = {
		charge_once_multi_regions: false,
		review_needed: false,
		use_pricing_level: false,
		services: [],
		regions: [],
		name: '',
		external_note: '',
		internal_note: '',
		currency: '',
		optional_rule: false,
		optional_rule_preselected: false,
		optional_rule_text: '',
		calculation_type: CALCULATION_TYPE.ABSOLUTE_VALUE,
		fee_type: '',
		object_level: props.object_level,
		object_id: props.object_id,
		calculation_field: '',
		calculation_field_equals: false,
		calculation_mode: '',
		calculation_value1: null,
		calculation_value2: '',
		fees: feesInit,
		calculation_values: [],
		calculation_value_condition: 'IS',
		disbursement_fee: false,
	};

	const filterInit = {
		object_level: props.isEdit ? props.object_level : 'SYSTEM',
		object_id: props.isEdit ? props.object_id : '',
		name: '',
		region: '',
		fee_type: '',
	};

	const errorsInit = {
		optional_rule_text: '',
		optionsError: false,
	};

	const [services, setServices] = useState([]);
	const [filter, setFilter] = useState(filterInit);
	const [pricingLevels, setPricingLevels] = useState([]);
	const [languages, setLanguages] = useState([{ label: '', value: '' }]);
	const [data, setData] = useState(dataInit);
	const [spinner, setSpinner] = useState(false);
	const [calculationValueFromListValue, setCalculationValueFromListValue] = useState('ENTITY_SIZE');
	const [feesAbsoluteValue, setFeesAbsoluteValue] = useState(feesInitAbsoluteValue);
	const [feesByAmount, setFeesByAmount] = useState(feesInitByAmount);
	const [calculation_value1_list, setValueFromList] = useState('');
	const [calculation_value1_select_unselect, setValueSelectUnselect] = useState('');
	const [rules, setRules] = useState([]);
	const [selectedRule, setSelectedRule] = useState(props.ruleToCustomize);
	const [errors, setErrors] = useState(errorsInit);
	const user = useSelector((state) => state.auth.user);
	const [currencies, setCurrencies] = useState([]);
	const badgeColors = {
		PROFESSIONAL: '#026EB3',
		OFFICIAL: '#10B5B5',
		TRANSLATION: '#BD2FEE',
	};

	const debouncedFilters = useDebounce(filter, DEBOUNCE_DELAY_MS);

	useEffect(() => {
		(async () => {
			try {
				let [ss, pl, ls, cs] = await Promise.all([Settings.GetServices(), PricingLevel.GetAll({ show_deleted: '0' }), Settings.GetLanguages(), Settings.GetExchangeRates()]);
				setServices(ss);
				setPricingLevels(pl);
				setLanguages([...languages, ...ls.map((l) => ({ label: l.name, value: l.id }))]);
				setCurrencies(cs.map((c) => ({ label: c.code, value: c.currency_id })));
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				let rulesData = [];
				if (props.object_level === 'CASE') {
					rulesData = await Estimates.GetEstimateRulesForQuote(id, filter);
					setRules(rulesData.data);
				} else {
					rulesData = await Estimates.GetAllRules(filter);
					setRules(rulesData.data);
				}

				let rule;
				if (props.isEdit) {
					rule = await Estimates.GetOneRulesByID(props.ruleToCustomize);
				} else {
					rule = await Estimates.GetOneRulesByID(rulesData?.data[0].id);
					// If it is new rule creation use the first rule values as default selected rule
					setSelectedRule(rule.id);
				}
				switch (rule.calculation_type) {
					case CALCULATION_TYPE.ABSOLUTE_VALUE:
						setFeesAbsoluteValue(rule.fees);
						break;
					case CALCULATION_TYPE.BY_AMOUNT:
						setFeesByAmount(rule.fees);
						break;
					case CALCULATION_TYPE.SELECTED_UNSELECTED:
						setValueSelectUnselect(rule.calculation_value1);
						break;
					case CALCULATION_TYPE.FROM_LIST:
					case CALCULATION_TYPE.DISCOUNT:
						setValueFromList(rule.calculation_value1);
						break;
				}
				setData(rule);
			} catch (err) {
				console.log(err);
			}
		})();
	}, [debouncedFilters]);

	useEffect(() => {
		switch (data.calculation_field) {
			case 'ENTITY_SIZE':
				setCalculationValueFromListValue(Lists.calculationValueFromListValueEntitySize);
				break;
			case 'LOCATION_OF_SEARCH':
				setCalculationValueFromListValue(Lists.calculationValueFromListValueLocationOfSearch);
				break;
			case 'APPLICATION_LANGUAGE':
				setCalculationValueFromListValue(languages);
				break;
			case 'UTILITY_MODEL_PRIORITY':
				setCalculationValueFromListValue(Lists.calculationValueFromListValueUtilityModelPriority);
				break;
			default:
				setCalculationValueFromListValue(Lists.calculationValueFromListValueEntitySize);
		}
	}, [data.calculation_field]);

	useEffect(() => {
		setFeesAbsoluteValue([...generateAbsFees()]);
		setFeesByAmount([...generateAbsFees()]);
	}, [pricingLevels]);

	const tabClick = (e) => {
		setData({
			...data,
			calculation_type: e.target.dataset.tab,
		});
	};

	const onFilterChange = (e) => {
		setFilter({
			...filter,
			[e.target.name]: e.target.value,
		});
	};

	const fieldUpdate = (e) => {
		switch (e.target.name) {
			case 'calculation_field_equals':
				setData({
					...data,
					[e.target.name]: e.target.checked,
				});
				break;
			case 'calculation_value1':
				setData({
					...data,
					[e.target.name]: Number(e.target.value),
				});
				break;
			case 'optional_rule':
				setData({
					...data,
					[e.target.name]: e.target.value.toLowerCase() === 'true',
				});
				break;
			case 'calculation_field':
				setData({
					...data,
					[e.target.name]: e.target.value,
					calculation_values: [],
				});
				break;
			case 'rule_type':
				setData({
					...data,
					calculation_type: e.target.value === RULE_TYPE.DISCOUNT ? CALCULATION_TYPE.DISCOUNT : CALCULATION_TYPE.ABSOLUTE_VALUE,
				});
				break;
			default:
				setData({
					...data,
					[e.target.name]: e.target.value,
				});
		}
	};

	const pricingLevelChange = (e) => {
		const [field, level_id, group] = e.target.name.split('__');
		if (data.calculation_type == CALCULATION_TYPE.ABSOLUTE_VALUE) {
			if (!feesAbsoluteValue.find((v) => v.level_id === level_id)) {
				return setFeesAbsoluteValue([...feesAbsoluteValue, { group: group, level_id: level_id, rule_id: selectedRule, value: e.target.value }]);
			}
			setFeesAbsoluteValue(
				feesAbsoluteValue.map((f, i) => {
					if (level_id === f.level_id) {
						f[field] = Number(e.target.value);
					}
					return f;
				}),
			);
		} else {
			if (!feesByAmount.find((v) => v.level_id === level_id && v.group == group)) {
				return setFeesByAmount([
					...feesByAmount,
					{
						group: group,
						level_id: level_id,
						rule_id: selectedRule,
						value: e.target.value,
					},
				]);
			}
			setFeesByAmount(
				feesByAmount.map((f, i) => {
					if (level_id === f.level_id && group == f.group) {
						f[field] = Number(e.target.value);
					}
					return f;
				}),
			);
		}
	};

	const calculationValue1OnChange = (e) => {
		if (data.calculation_type === CALCULATION_TYPE.SELECTED_UNSELECTED) {
			setValueSelectUnselect(e.target.value);
		} else {
			setValueFromList(e.target.value);
		}
	};

	const pricingRangeChange = (e) => {
		const [field, group] = e.target.name.split('__');
		let b = feesByAmount.find((f) => f.group == group);
		let c = {};
		let range_error = '';
		if (field === 'range_to' && b.range_from) {
			c = { range_from: b.range_from, range_to: Number(e.target.value) };
		} else if (field === 'range_from' && b.range_to) {
			c = { range_from: Number(e.target.value), range_to: b.range_to };
		}
		if (c.range_from && c.range_to) {
			let range = c.range_from > c.range_to;
			feesByAmount.forEach((element) => {
				if (group != element.group) {
					if (range || areOverlapping(element, c)) {
						range_error = 'error';
					}
				}
			});
		}

		setFeesByAmount(
			feesByAmount.map((f) => {
				if (f.group == group) {
					f[field] = Number(e.target.value);
					f.range_error = range_error;
				}
				return f;
			}),
		);
	};

	const areOverlapping = (rangeA, rangeB) => {
		if (rangeB.range_from < rangeA.range_from) {
			return rangeB.range_to >= rangeA.range_from;
		} else {
			return rangeB.range_from <= rangeA.range_to;
		}
	};

	const generateAbsFees = (group = 0) => {
		return pricingLevels.map((pl) => {
			return {
				level_id: pl.id,
				value: undefined,
				range_from: undefined,
				range_to: undefined,
				group: group,
			};
		});
	};

	const switchChange = (e) => {
		setData({
			...data,
			[e.target.name]: e.target.checked,
		});
	};

	const addRange = () => {
		let groups = getFeeGroups(feesByAmount);
		let g = groups[groups.length - 1];
		if (g !== undefined) {
			g = g + 1;
		} else {
			g = 0;
		}
		setFeesByAmount([...feesByAmount, ...generateAbsFees(g)]);
	};

	const getFeeGroups = (fees) => {
		let groups = [];
		for (let f of fees) {
			if (!groups.includes(f.group)) groups.push(f.group);
		}
		return groups.sort();
	};

	const removeRange = (e) => {
		let fees_by_amount = feesByAmount.filter((f) => f.group != e.target.dataset.group);
		// corect fees groups
		fees_by_amount = fees_by_amount.map((f) => {
			if (f.group > e.target.dataset.group) {
				return { ...f, group: f.group - 1 };
			}
			return f;
		});
		setFeesByAmount(fees_by_amount);
	};

	const newSubmit = async () => {
		let ruleData = { ...data };
	};

	const submit = async () => {
		if ([CALCULATION_TYPE.FROM_LIST, CALCULATION_TYPE.DISCOUNT].includes(data.calculation_type)) {
			data.calculation_value1 = calculation_value1_list;
		}
		if (data.calculation_type == CALCULATION_TYPE.SELECTED_UNSELECTED) {
			data.calculation_value1 = calculation_value1_select_unselect;
		}
		if (data.calculation_type == CALCULATION_TYPE.VALUELESS) {
			data.calculation_field = '';
		}
		if (data.calculation_type == CALCULATION_TYPE.ABSOLUTE_VALUE) {
			data.calculation_field = '';
			data.fees = feesAbsoluteValue;
		}
		if (data.calculation_type == CALCULATION_TYPE.BY_AMOUNT) {
			let fees = feesByAmount.sort((a, b) => {
				return a.range_from - b.range_from;
			});
			// rearange groups
			let i = 0,
				g = 0;
			let newFees = fees.map((f) => {
				f.group = g;
				i++;
				if (i === pricingLevels.length) {
					g++;
					i = 0;
				}
				return f;
			});
			data.fees = newFees;
		}
		let new_rule = {};
		if (props.isEdit) {
			new_rule = data;
		} else {
			new_rule = { ...data, object_level: props.object_level, object_id: props.object_id, id: '' };
		}

		let validation = validateRule(new_rule, pricingLevels);
		setErrors(validation.errors);
		if (!validation.isValid) {
			props.sendNotification({ type: 'error', title: 'Rule Creation Failed. Fix errors above.' });
			return;
		}

		if (![CALCULATION_TYPE.ABSOLUTE_VALUE, CALCULATION_TYPE.BY_AMOUNT].includes(data.calculation_type)) {
			setData({
				...data,
				fees: [],
			});
		}
		try {
			setSpinner(true);
			if (data.object_level === props.object_level) {
				await Estimates.UpdateRule(data.id, { ...data, created_by: user.uid, object_level: props.object_level, object_id: id, parent_id: data.parent_id });
				setSpinner(false);
				props.sendNotification({ type: 'info', title: 'Rule Saved.' });
				props.closeHandler();
			} else {
				await Estimates.CreateRule({ ...data, id: '', created_by: user.uid, object_level: props.object_level, object_id: id, parent_id: data.parent_id });
				setSpinner(false);
				props.sendNotification({ type: 'info', title: 'Rule Created.' });
				props.closeHandler();
			}
		} catch (err) {
			console.log(err);
			setSpinner(false);
		}
	};

	const groupBy = (xs, key) => {
		return xs.reduce(function (rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};

	const objToArray = (obj) => {
		let out = [];
		Object.keys(obj).forEach(function (key, index) {
			out.push(obj[key]);
		});
		return out;
	};

	const tableHeaders = [
		{ title: 'Rule Name', field: 'case_instruction_date', type: 'date', sort: 'none', sortable: false },
		{ title: 'Fees', field: 'client_first_name', type: 'string', sort: 'none', sortable: false },
	];

	const setRuleToCustomize = async (e) => {
		let rule_id = e.currentTarget.dataset.id;
		setSelectedRule(rule_id);
		let rule = await Estimates.GetOneRulesByID(rule_id);

		switch (rule.calculation_type) {
			case CALCULATION_TYPE.ABSOLUTE_VALUE:
				setFeesAbsoluteValue(rule.fees);
				break;
			case CALCULATION_TYPE.BY_AMOUNT:
				setFeesByAmount(rule.fees);
				break;
			case CALCULATION_TYPE.SELECTED_UNSELECTED:
				setValueSelectUnselect(rule.calculation_value1);
				break;
			case CALCULATION_TYPE.FROM_LIST:
			case CALCULATION_TYPE.DISCOUNT:
				setValueFromList(rule.calculation_value1);
				break;
		}
		setData(rule);
	};

	const modalActions = [
		{ primary: false, label: 'Close', action: props.closeHandler, theme: 'azami-ghost' },
		{ primary: true, label: 'Save Rule', action: submit, theme: 'blurple' },
	];

	return (
		<Modal title={props.title} closeHandler={props.closeHandler} footerActions={modalActions}>
			<div className="customize-rule-modal__body">
				{!props.isEdit ?
					<div className="customize-rule-modal__body-rules-list">
						<div className="customize-rule-modal__body-rules-list__header">Available Rules</div>
						<div className="customize-rule-modal__body-rules-list-search-input">
							<Input label="Search by Rule Name" name="name" value={filter.name} onChange={onFilterChange} />
							<div className="select-filters">
								<Select options={props.services} label="Service" name="service" value={filter.service} onChange={onFilterChange} />
								<Select options={props.regions} label="Region" name="region" value={filter.region} onChange={onFilterChange} />
							</div>
							<DataTable fixedHeader={false} columns={tableHeaders}>
								{rules.map((r) => (
									<tr key={r.id} onClick={setRuleToCustomize} data-id={r.id} className={r.id == selectedRule ? 'customize-rule-modal__body-rules-list__selected' : ''}>
										<DataTable.Cell customClassName="customize-rule-modal__body-rules-list__rule-name" title={r.name}>
											{r.name}
										</DataTable.Cell>
										<DataTable.Cell>
											<LetterBadge color={badgeColors[r.fee_type]}>{r.fee_type}</LetterBadge>
										</DataTable.Cell>
									</tr>
								))}
							</DataTable>
						</div>
					</div>
				:	null}
				<div className="customize-rule-modal__body-rule-content">
					<div className={`customize-rule-modal__body-rule-content-notes-container ${props.isEdit ? 'rule-content-notes-container-edit' : ''}`}>
						<div>
							<Textarea label="External Note" customClassName="customize-rule-modal__body-rule-content-notes" name="external_note" value={data.external_note} onChange={fieldUpdate} />
							<Textarea label="Internal Note" customClassName="customize-rule-modal__body-rule-content-notes" name="internal_note" value={data.internal_note} onChange={fieldUpdate} />
						</div>
						<div className="customize-rule-modal__body-rule-content__info">
							{props.isEdit ?
								<div className="customize-rule-modal__body-rule-content__info-item">
									<span className="title">Rule&nbsp;Name</span>
									<span>{data.name}</span>
								</div>
							:	null}
							<div className="customize-rule-modal__body-rule-content__info-item">
								<span className="title">Last&nbsp;Edited</span>
								<span>{data.created_by_name ? data.created_by_name : ''}</span>
								<span className="last-edited-date">
									<AppDate>{data._modified}</AppDate>
								</span>
							</div>
						</div>
					</div>

					{/* OPTIONS */}
					<details className="customize-rule-modal__body-rule-options-collapsable" open>
						<summary className={`customize-rule-modal__body-rule-options-header ${errors.optionsError ? 'estimator-rules-summary-error' : ''}`}>OPTIONS</summary>
						<div className="customize-rule-modal__body-rule-options-body">
							<Select label="Currency" customClassName="text-input" options={currencies} name="currency" onChange={fieldUpdate} value={data.currency} />
							<span className="customize-rule-modal__body-rule-options-body-subtitle">Fees</span>
							<div className="customize-rule-modal__body-rule-options-body-section">
								<RadioButton
									label="Professional"
									customClassName="fees-radio"
									name="fee_type"
									themeCheck={'blurple'}
									value="PROFESSIONAL"
									onChange={fieldUpdate}
									checked={data.fee_type == 'PROFESSIONAL' ? true : false}
								/>
								<RadioButton
									label="Translation"
									customClassName="fees-radio"
									name="fee_type"
									themeCheck={'blurple'}
									value="TRANSLATION"
									onChange={fieldUpdate}
									checked={data.fee_type == 'TRANSLATION' ? true : false}
								/>
								<RadioButton
									label="Official"
									customClassName="fees-radio"
									name="fee_type"
									themeCheck={'blurple'}
									value="OFFICIAL"
									onChange={fieldUpdate}
									checked={data.fee_type == 'OFFICIAL' ? true : false}
								/>
							</div>
							<span className={`customize-rule-modal__body-rule-options-body-subtitle`}>Rule</span>
							<div className="customize-rule-modal__body-rule-options-body-section">
								<RadioButton
									label="Standard"
									customClassName="fees-radio"
									name="rule_type"
									value={RULE_TYPE.STANDARD}
									onChange={fieldUpdate}
									checked={data.calculation_type !== CALCULATION_TYPE.DISCOUNT ? true : false}
									themeCheck={'blurple'}
								/>
								<RadioButton
									label="Discount"
									customClassName="fees-radio"
									name="rule_type"
									value={RULE_TYPE.DISCOUNT}
									onChange={fieldUpdate}
									checked={data.calculation_type === CALCULATION_TYPE.DISCOUNT ? true : false}
									themeCheck={'blurple'}
								/>
							</div>
							<span className="customize-rule-modal__body-rule-options-body-subtitle">Importance</span>
							<div className="customize-rule-modal__body-rule-options-body-section">
								<RadioButton
									label="Mandatory"
									customClassName="fees-radio"
									name="optional_rule"
									themeCheck={'blurple'}
									value={false}
									onChange={fieldUpdate}
									checked={data.optional_rule ? false : true}
								/>
								<RadioButton
									label="Optional"
									customClassName="fees-radio"
									name="optional_rule"
									themeCheck={'blurple'}
									onChange={fieldUpdate}
									value={true}
									checked={data.optional_rule ? true : false}
								/>
							</div>
							{data.optional_rule ?
								<blockquote>
									<Switch
										label="Preselected"
										customClassName="switch-list"
										name="optional_rule_preselected"
										theme="blurple"
										value={data.optional_rule_preselected}
										onChange={switchChange}
									/>
									<Textarea
										label="Note"
										customClassName="customize-rule-modal__body-rule-content-notes"
										name="optional_rule_text"
										onChange={fieldUpdate}
										value={data.optional_rule_text}
										error={errors.optional_rule_text}
									/>
								</blockquote>
							:	null}
							<Switch
								name="review_needed"
								value={data.review_needed}
								theme="blurple"
								onChange={switchChange}
								label="Quote must be reviewed by Estimates Team"
								customClassName="switch-list"
							/>
							<Switch name="disbursement_fee" value={data.disbursement_fee} onChange={switchChange} label="Disbursement Fee" customClassName="switch-list" theme={'blurple'} />
						</div>
					</details>

					{/* CALCULATION TYPE */}
					{data.calculation_type !== CALCULATION_TYPE.DISCOUNT ?
						<details className="customize-rule-modal__body-rule-options-collapsable" open>
							<summary className={`customize-rule-modal__body-rule-options-header ${errors.feesError ? 'estimator-rules-summary-error' : ''}`}>Calculation type</summary>
							<div className="customize-rule-modal__body-rule-options-body">
								<ul className="customize-rule-modal__body-rule-options-collapsable-tabs">
									<li onClick={tabClick} className={data.calculation_type === CALCULATION_TYPE.ABSOLUTE_VALUE ? 'active' : ''} data-tab={CALCULATION_TYPE.ABSOLUTE_VALUE}>
										Absolute Value
									</li>
									<li onClick={tabClick} className={data.calculation_type === CALCULATION_TYPE.BY_AMOUNT ? 'active' : ''} data-tab={CALCULATION_TYPE.BY_AMOUNT}>
										By Amount
									</li>
									<li onClick={tabClick} className={data.calculation_type === CALCULATION_TYPE.SELECTED_UNSELECTED ? 'active' : ''} data-tab={CALCULATION_TYPE.SELECTED_UNSELECTED}>
										Selected/Unselected
									</li>
									<li onClick={tabClick} className={data.calculation_type === CALCULATION_TYPE.FROM_LIST ? 'active' : ''} data-tab={CALCULATION_TYPE.FROM_LIST}>
										Value From List
									</li>
									<li onClick={tabClick} className={data.calculation_type === CALCULATION_TYPE.VALUELESS ? 'active' : ''} data-tab={CALCULATION_TYPE.VALUELESS}>
										Valueless
									</li>
								</ul>
								{data.calculation_type === CALCULATION_TYPE.ABSOLUTE_VALUE ?
									<div className="customize-rule-modal__body-rule-options-collapsable-tabs-content">
										<ul className="customize-rule-modal-levels-input-list">
											{data.use_pricing_level ?
												pricingLevels.map((pl) => {
													return (
														<li key={pl.id}>
															<span className="customize-rule-modal-levels-input-list__label">{pl.name}</span>
															<PricingLevelPreview
																onChange={pricingLevelChange}
																ruleServices={data.services}
																pricingLevel={pl}
																services={services}
																pricingLevels={pricingLevels}
																fees={feesAbsoluteValue}
															/>
														</li>
													);
												})
											:	pricingLevels.map((pl) => {
													if (pl.is_base) {
														let v = feesAbsoluteValue.find((f) => f.level_id === pl.id);
														return (
															<li>
																<span className="customize-rule-modal-levels-input-list__label">{pl.name}</span>
																<Input type="number" name={`value__${pl?.id}__0`} value={v?.value} onChange={pricingLevelChange} />
															</li>
														);
													}
												})
											}
										</ul>
									</div>
								:	null}
								{data.calculation_type === CALCULATION_TYPE.BY_AMOUNT ?
									<div className="customize-rule-modal__body-rule-options-collapsable-tabs-content">
										<div className="customize-rule-modal__body-tab-content-container__line">
											<Select
												label="Field"
												customClassName="option-dd"
												options={Lists.calculationByAmountField}
												name="calculation_field"
												value={data.calculation_field}
												onChange={fieldUpdate}
											/>
											<Select
												label="Mode"
												customClassName="option-dd"
												options={Lists.calculationByAmountMode}
												name="calculation_mode"
												value={data.calculation_mode}
												onChange={fieldUpdate}
											/>
											<Button className="option-btn" onClick={addRange}>
												Add Range
											</Button>
										</div>
										<div className="customize-rule-modal-input-ranges">
											{objToArray(groupBy(feesByAmount, 'group')).map((bar) => {
												return (
													<ul className="customize-rule-modal-levels-input-list" key={bar[0].group}>
														<li>
															<span className="customize-rule-modal__body-tab-content-text">Range</span>
															<button className="customize-rule-modal-levels-input-list__delete-button" data-group={bar[0].group} onClick={removeRange}></button>
														</li>
														<li>
															<Input
																type="number"
																placeholder="from"
																name={`range_from__${bar[0].group}`}
																value={bar[0].range_from}
																onChange={pricingRangeChange}
																error={bar[0].range_error}
																customClassName="customize-rule-modal-levels-input-list__range"
															/>
															<span className="customize-rule-modal-levels-input-list__separator"> - </span>
															<Input
																type="number"
																placeholder="to"
																name={`range_to__${bar[0].group}`}
																value={bar[0].range_to}
																onChange={pricingRangeChange}
																error={bar[0].range_error}
																customClassName="customize-rule-modal-levels-input-list__range"
															/>
														</li>
														{data.use_pricing_level ?
															pricingLevels.map((pl) => {
																return (
																	<li key={`${pl?.id}__${bar[0].group}`}>
																		<span className="customize-rule-modal-levels-input-list__label">{pl.name}</span>
																		<PricingLevelPreview
																			onChange={pricingLevelChange}
																			ruleServices={data.services}
																			pricingLevel={pl}
																			services={services}
																			pricingLevels={pricingLevels}
																			fees={feesByAmount}
																			group={bar[0].group}
																		/>
																	</li>
																);
															})
														:	pricingLevels.map((pl) => {
																if (pl.is_base) {
																	let v = feesByAmount.find((f) => f.level_id === pl.id && f.group === bar[0].group);
																	return (
																		<li>
																			<span className="customize-rule-modal-levels-input-list__label">{pl.name}</span>
																			<Input type="number" name={`value__${pl.id}__${bar[0].group}`} value={v?.value} onChange={pricingLevelChange} />
																		</li>
																	);
																}
															})
														}
													</ul>
												);
											})}
										</div>
									</div>
								:	null}
								{data.calculation_type === CALCULATION_TYPE.SELECTED_UNSELECTED ?
									<div className="customize-rule-modal__body-rule-options-collapsable-tabs-content">
										<div className="customize-rule-modal__body-tab-content-container__line">
											<Input
												type="number"
												customClassName="option-dd no-label-input"
												placeholder="Value"
												name="calculation_value1"
												value={!isNaN(parseFloat(calculation_value1_select_unselect)) ? calculation_value1_select_unselect : undefined}
												onChange={calculationValue1OnChange}
												error={errors.calculationValue1}
											/>
											<Switch
												label="If following field is"
												customClassName="option-sw"
												theme="blurple"
												value={data.calculation_field_equals}
												name="calculation_field_equals"
												onChange={fieldUpdate}
											/>
											<Select
												label="Field"
												customClassName="option-dd"
												options={Lists.calculationSelectUnselectedField}
												value={data.calculation_field}
												name="calculation_field"
												onChange={fieldUpdate}
												error={errors.calculationField}
											/>
										</div>
									</div>
								:	null}
								{data.calculation_type === CALCULATION_TYPE.FROM_LIST ?
									<div className="customize-rule-modal__body-rule-options-collapsable-tabs-content">
										<div className="customize-rule-modal__body-tab-content-container__line">
											<Input
												type="number"
												customClassName="option-dd no-label-input"
												placeholder="Value"
												value={!isNaN(parseFloat(calculation_value1_list)) ? calculation_value1_list : undefined}
												name="calculation_value1"
												onChange={calculationValue1OnChange}
												error={errors.calculationValue1}
											/>
											<span className="line-text">for field</span>
											<Select
												label="Field"
												customClassName="option-dd"
												options={Lists.calculationValueFromListField}
												value={data.calculation_field}
												name="calculation_field"
												onChange={fieldUpdate}
												error={errors.calculationField}
											/>
											<span className="line-text">and its value</span>
											<Select
												label="Condition"
												customClassName="option-dd"
												options={Lists.calculationValueConditions}
												value={data.calculation_value_condition}
												name="calculation_value_condition"
												onChange={fieldUpdate}
											/>
											{['IS', 'IS_NOT'].includes(data.calculation_value_condition) ?
												<Select
													label="Value"
													customClassName="option-dd"
													options={calculationValueFromListValue}
													value={data.calculation_value2}
													name="calculation_value2"
													onChange={fieldUpdate}
													error={errors.calculationValue2}
												/>
											:	<TagSelect
													label="Value"
													customClassName="option-dd"
													options={calculationValueFromListValue}
													values={data.calculation_values}
													name="calculation_values"
													onChange={fieldUpdate}
													error={errors.calculationValues}
												/>
											}
										</div>
									</div>
								:	null}
								{data.calculation_type === CALCULATION_TYPE.VALUELESS ?
									<div className="customize-rule-modal__body-rule-options-collapsable-tabs-content">
										<span className="customize-rule-modal__body-tab-content-text line-text">This rule is valueless</span>
									</div>
								:	null}
							</div>
						</details>
					:	null}

					{/* DISCOUNT CONDITIONS */}
					{data.calculation_type === CALCULATION_TYPE.DISCOUNT ?
						<details className="customize-rule-modal__body-rule-options-collapsable" open>
							<summary className={`customize-rule-modal__body-rule-options-header ${errors.discountsError ? 'estimator-rules-summary-error' : ''}`}>Discount Conditions</summary>
							<div className="customize-rule-modal__body-rule-options-body">
								<div className="customize-rule-modal__body-rule-options-collapsable-tabs-content">
									<div className="customize-rule-modal__body-tab-content-container__line">
										<Select
											label="Field"
											customClassName="option-dd"
											options={Lists.calculationValueFromListField}
											value={data.calculation_field}
											name="calculation_field"
											onChange={fieldUpdate}
										/>
										<Select
											label="Value"
											customClassName="option-dd"
											options={calculationValueFromListValue}
											value={data.calculation_value2}
											name="calculation_value2"
											onChange={fieldUpdate}
										/>
										<Input
											label="Discount"
											type="number"
											customClassName="option-dd"
											placeholder="%"
											value={calculation_value1_list}
											name="calculation_value1"
											onChange={calculationValue1OnChange}
										/>
									</div>
								</div>
							</div>
						</details>
					:	null}
				</div>
			</div>
		</Modal>
	);
};

export default CustomizeEstimateRule;
