import React, { useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { OwnerContext } from 'util/ownerContext';
import AppDate from 'com/util/AppDate';
import './style.css';

const Input = forwardRef((props, ref) => {
	const errorBorder = props.error ? 'form-input__error-border' : '';
	const userData = useSelector((state) => state.auth.user);
	const ownerData = useContext(OwnerContext);

	// checkRoles function with context data
	const checkRoles = (uData, roles) => {
		if (props.disabled) return props.disabled;
		if (uData.role.includes('admin')) return false;
		if (roles.includes('$user') && ownerData.userMatch) return false;
		if (roles.includes('$firm') && ownerData.firmMatch) return false;
		if (roles.includes('$clientOfFirm') && ownerData.clientOfFirmMatch) return false;
		if (roles.includes('$agentOfFirm') && ownerData.agentOfFirmMatch) return false;
		if (roles.length === 0) return false;
		let uRoles = uData.role.filter((r) => {
			return roles.includes(r);
		});
		return uRoles.length === 0;
	};

	let disabled = checkRoles(userData, props.forRoles) ? 'disabled' : '';

	const disableScroll = (e) => e.target.blur();

	return (
		<label className={`form-input ${props.customClassName}`}>
			<span className={`form-input__label ${props.theme} ${props.info ? 'info' : ''}`}>
				{props.label}
				{props.required ?
					<span className="form-input__label-required" title={'This field is required.'}>
						&#42;
					</span>
				:	null}
				{props.icons.length > 0 &&
					props.icons.map((icon, i) => {
						if (icon.icon) {
							return (
								<div key={i} className="form-input__label-info" title={`${icon.tooltip || ''}`}>
									<img src={icon.icon} alt="" />
								</div>
							);
						}
						return null;
					})}
			</span>
			{props.description ?
				<span className="form-input__description">{props.description}</span>
			:	null}
			<input
				disabled={disabled}
				className={`form-input__input ${props.theme} ${disabled} ${errorBorder} `}
				placeholder={props.placeholder}
				type={props.type}
				onChange={props.onChange}
				onBlur={props.onBlur}
				onKeyUp={props.onKeyUp}
				value={
					typeof props.value !== 'undefined' ?
						props.type === 'number' ?
							Number(props.value).toString()
						:	props.value
					:	undefined
				}
				name={props.name}
				// defaultValue={typeof props.defaultValue !== 'undefined' ? props.defaultValue : undefined}
				onKeyDown={props.onKeyDown}
				ref={ref}
				onWheel={disableScroll}
				onMouseDown={props.onMouseDown}
			/>
			{props.error && <span className="form-input__error">{props.error}</span>}
			{!props.error && props.previewValue && (
				<span className="form-input__preview" title={props.previewToolTip}>
					{props.type === 'date' ?
						<AppDate>{props.previewValue}</AppDate>
					:	props.previewValue}
				</span>
			)}
		</label>
	);
});

Input.propTypes = {
	theme: PropTypes.oneOf(['light', 'white', 'dark']),
	customClassName: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.any,
	defaultValue: PropTypes.any,
	error: PropTypes.string,
	previewValue: PropTypes.any,
	previewToolTip: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	disabled: PropTypes.bool,
	forRoles: PropTypes.array,
	onKeyDown: PropTypes.func,
	required: PropTypes.bool,
	/**
	 * this is depricated property and should be removed in couple of sprints
	 * the new way of sending icons to the component is by using icons prop
	 */
	// infoIconColor: PropTypes.oneOf(['magenta', 'grey', 'sync-green', 'sync-red']),
	/**
	 * this is depricated property and should be removed in couple of sprints
	 * the new way of sending icons to the component is by using icons prop
	 */
	// info: PropTypes.string,
	icons: PropTypes.array,
	description: PropTypes.string,
	onMouseDown: PropTypes.func,
};

Input.defaultProps = {
	theme: 'light',
	customClassName: '',
	label: '',
	placeholder: '',
	type: 'text',
	name: '',
	error: '',
	previewToolTip: '',
	required: false,
	onChange: () => {},
	onBlur: () => {},
	disabled: false,
	forRoles: [],
	onKeyDown: () => {},
	/**
	 * this is depricated property and should be removed in couple of sprints
	 * the new way of sending icons to the component is by using icons prop
	 */
	// info: '',
	/**
	 * this is depricated property and should be removed in couple of sprints
	 * the new way of sending icons to the component is by using icons prop
	 */
	// infoIconColor: 'magenta',
	icons: [],
	description: '',
	onMouseDown: () => {},
};

export default Input;
