import { useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { setQuoteData, setQuoteDataFromObject } from 'redux/ducks/quote';
import { setWipoData } from 'redux/ducks/wipo';

import Settings from 'services/rest/settings';
import Users from 'services/rest/users';
import Wipo from 'services/wipo/wipo';

import Loader from 'com/ui/Loader';
import Button from 'com/ui/Button';
import Input from 'com/ui/Input';
import Select from 'com/ui/Select';
import { RoleBox } from 'com/util/RoleBox';
import ApplicationNumberFormatModal from 'com/widgets/QuoteModals/ApplicationNumberFormatModal';
import QuoteDetailsWipoModal from 'com/pages/QuotesDetails/QuoteDetailsWipoModal';
import ChinaExtensionModal from 'com/widgets/QuoteModals/ChinaExtensionModal';

import { requiredQuoteDetailsField, validateApplicationNumberFormat, calculateChinaExtensionSwitch } from 'services/validators/quotes';
import { formatApplicationNumber } from 'services/strings';
import Lists from 'com/pages/SystemSettingsEstimatorRules/lists';
import { applicationNumberLabel } from './QuoteDetailsCustomFieldsLists';

import { INTERNAL_ROLES, ROLE_TYPES, SERVICE_KEYS, APP_NUM_IGNORED_KEY_WORDS } from 'data/constants';

import './style.css';

const IdentifiersBase = (props) => {
	const user = useSelector((state) => state.auth.user);
	const [showAppNumberModal, setShowAppNumberModal] = useState(false);
	const [wipoModalData, setWipoModalData] = useState({});
	const [showWipoModal, setShowWipoModal] = useState(false);
	const [wipoSpinner, setWipoSpinner] = useState(false);
	const [chinaExtensionModal, setChinaExtensionModal] = useState(false);
	const filterData = {
		firm_id: '',
		order_by: [{ field: 'first_name', direction: 'up' }],
	};

	const fieldChange = (e) => {
		props.setQuoteData({ name: e.target.name, value: e.target.value });
	};

	const firmChanged = async (e) => {
		try {
			let firmID = e.target.value;
			let filter = {
				order_by: filterData.order_by.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(','),
				firm_id: firmID,
				ignore_inactive_users: true,
			};
			let clientsData = await Users.GetAll(filter);
			props.setClients([
				{ label: '', value: '' },
				...clientsData.data.map((c) => {
					return { label: `${c.first_name} ${c.last_name}`, value: c.id };
				}),
			]);
			let firm = props.firms.find((f) => f.value === firmID);
			let clientFirmNotes = firm && firm.notes;

			props.setQuoteData({ name: 'firm_notes', value: clientFirmNotes });
			props.setQuoteData({ name: 'client_firm_id', value: firmID });

			if (props.quote.service_id && props.quote.application_number) {
				let duplicate = await props.checkDuplicates(props.quote.service_id, props.quote.application_number, firmID);
				if (duplicate) {
					props.setErrors({
						...props.errors,
						application_number: 'Already Existing',
					});
					return;
				}
			}

			if (props.errors.application_number) {
				props.setErrors({
					...props.errors,
					application_number: '',
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleAppNumberOnBlur = async () => {
		if (props.quote.service_id) {
			let service = props.services.find((s) => s.id == props.quote.service_id);
			let application_number = props.quote.application_number;
			if (service.key === SERVICE_KEYS.PCT && !APP_NUM_IGNORED_KEY_WORDS.includes(application_number.toUpperCase())) {
				if (validateApplicationNumberFormat(props.quote.application_number)) {
					application_number = formatApplicationNumber(application_number);
					props.setQuoteData({ name: 'application_number', value: application_number });
				} else {
					props.setErrors({
						...props.errors,
						application_number: 'Format',
					});
					setShowAppNumberModal(true);
					return;
				}
			}

			if (props.quote.service_id && props.quote.client_firm_id) {
				let duplicate = await props.checkDuplicates(props.quote.service_id, application_number, props.quote.client_firm_id);
				if (duplicate) {
					props.setErrors({
						...props.errors,
						application_number: 'Already Existing',
					});
					return;
				}
			}

			if (props.errors.application_number) {
				props.setErrors({
					...props.errors,
					application_number: '',
				});
			}
			//New Quote
			if (props.newQuote && !APP_NUM_IGNORED_KEY_WORDS.includes(application_number.toUpperCase())) {
				if (props.wipo.application_number !== application_number) {
					let wipoData = await handleGetWipo(application_number, service);
					if (wipoData) {
						if (service.key === SERVICE_KEYS.PCT) {
							let date = wipoData.earliest_priority_date || wipoData.intl_filing_date;
							if (date) {
								let china_extension_fee = calculateChinaExtensionSwitch(new Date(date));
								if (china_extension_fee) {
									wipoData.china_extension_fee = china_extension_fee;
								}
							}
						}
						props.setWipoData({
							application_number: application_number,
							wipo_data: wipoData,
						});
						if (!wipoData.claims_count_accurate && (service.key === SERVICE_KEYS.PCT || service.key === SERVICE_KEYS.UTILITY)) {
							let data = cleanWipo(wipoData);
							props.setQuoteDataFromObject(data);
						} else {
							props.setQuoteDataFromObject(wipoData);
						}
					}
				}
			} else {
				return;
			}
		}
	};

	const cleanWipo = (wipoData) => {
		// deconstruct to remove number_of_claims and number_of_independent_claims for external users
		if (user.roleType === ROLE_TYPES.EXTERNAL) {
			const { number_of_claims: a, number_of_independent_claims: b, ...tmp } = wipoData;
			return tmp;
		}
		return wipoData;
	};

	const handleGetWipo = async (application_number, service) => {
		if (Wipo.enableGetWipo(props.quote.service_id, props.services)) {
			try {
				let app_number = application_number;
				if (service.key === SERVICE_KEYS.PCT) {
					app_number = formatApplicationNumber(app_number);
				}
				if (service.key === SERVICE_KEYS.UTILITY || service.key === SERVICE_KEYS.PARIS) {
					app_number = formatApplicationNumber(app_number);
					if (!validateApplicationNumberFormat(app_number)) {
						return false;
					}
				}
				let payload = Wipo.formatWipoRequestObj(app_number, props.quote.service_id);
				let result = await Settings.GetWipo(payload);
				if (result.succeed) {
					let res = result.result;
					let app_language = props.languages.find((l) => l.code === res.application_language);
					res.application_language = app_language.value;
					let location_of_search = res.location_of_search || 'NO_REPORT';
					res.location_of_search = Lists.calculationValueFromListValueLocationOfSearch.some((l) => l.value === location_of_search) ? location_of_search : 'OTHER';
					return res;
				}
				return false;
			} catch (err) {
				console.log(err);
				return false;
			}
		}
		return false;
	};

	const processWipoPrewievData = (wipoData) => {
		if (wipoData.earliest_priority_date) {
			let [year, month, day] = wipoData.earliest_priority_date.split('-');
			wipoData.earliest_priority_date = `${month}/${day}/${year}`;
		}
		if (wipoData.intl_filing_date) {
			let [year, month, day] = wipoData.intl_filing_date.split('-');
			wipoData.intl_filing_date = `${month}/${day}/${year}`;
		}
		wipoData.location_of_search = wipoData.location_of_search || 'NO_REPORT';
		wipoData.location_of_search = Lists.calculationValueFromListValueLocationOfSearch.some((l) => l.value === wipoData.location_of_search) ? wipoData.location_of_search : 'OTHER';
		return wipoData;
	};

	const handleGetWipoButton = async () => {
		if (props.quote.service_id) {
			let service = props.services.find((s) => s.id == props.quote.service_id);
			if (!props.newQuote && !APP_NUM_IGNORED_KEY_WORDS.includes(props.quote.application_number.toUpperCase()) && service) {
				try {
					setWipoSpinner(true);
					let data = await handleGetWipo(props.quote.application_number, service);
					if (data) {
						setWipoModalData(data);
						setShowWipoModal(true);
					}
					setWipoSpinner(false);
				} catch (err) {
					setWipoSpinner(false);
					console.log(err);
				}
			}
		}
	};

	const overwriteDataHandler = (wipoData) => {
		let service = props.services.find((s) => s.id == props.quote.service_id);
		if (service.key === SERVICE_KEYS.PCT) {
			let date = wipoData.earliest_priority_date || wipoData.intl_filing_date;
			if (date) {
				let china_extension_fee = calculateChinaExtensionSwitch(new Date(date));
				if (china_extension_fee) {
					setChinaExtensionModal(true);
					wipoData.china_extension_fee = china_extension_fee;
				}
			}
		}
		props.setWipoData({
			application_number: props.quote.application_number,
			wipo_data: wipoData,
		});

		if (!wipoData.claims_count_accurate && (service.key === SERVICE_KEYS.PCT || service.key === SERVICE_KEYS.UTILITY)) {
			let data = cleanWipo(wipoData);
			props.setQuoteDataFromObject(data);
		} else {
			props.setQuoteDataFromObject(wipoData);
		}
	};

	const appNumFormatModalClose = () => {
		setShowAppNumberModal(false);
	};

	const appNumberModalActions = [{ primary: true, label: 'Cancel', action: appNumFormatModalClose, theme: 'azami-ghost' }];

	const closeWipoModalData = () => {
		setShowWipoModal(false);
	};

	const wipoService = (service_id) => {
		let service = props.services.find((s) => s.id === service_id);
		if (service) {
			if (service.name === 'European Validation') {
				return 'EPO';
			}
		} else return 'WIPO';
	};

	const chinaExtensionModalClose = () => {
		setChinaExtensionModal(false);
	};

	const chinaExtensionModalActions = [{ primary: true, label: 'Cancel', action: chinaExtensionModalClose, theme: 'azami-ghost' }];

	return (
		<div className="quote-details__section-container">
			<div className="new-quote-wizard__content-section-identifiers-container">
				{/* <h4 className={`quote-details__subtitle ${props.errors.application_number || props.errors.client_id ? 'section-title-error' : ''}`}>Identifiers</h4> */}
				<div className="identifiers-container__col">
					<div className="application_number_field">
						<Input
							name="application_number"
							label={applicationNumberLabel(props.quote.service_id, props.services, SERVICE_KEYS)}
							value={props.quote.application_number}
							onChange={fieldChange}
							onBlur={handleAppNumberOnBlur}
							required={requiredQuoteDetailsField(props.quote.service_id, props.services, 'application_number', user.roleType === ROLE_TYPES.EXTERNAL)}
							error={props.errors.application_number}
						/>
						{!props.newQuote ?
							<RoleBox roles={INTERNAL_ROLES}>
								{Wipo.enableGetWipo(props.quote.service_id, props.services) ?
									<Button onClick={handleGetWipoButton} className={'get-wipo-button'} showSpinner={wipoSpinner}>
										Get WIPO
									</Button>
								:	null}
							</RoleBox>
						:	null}
					</div>
					<Input name="client_ref_number" label="Client Reference Number" value={props.quote.client_ref_number} onChange={fieldChange} error={props.errors.client_ref_number} />
				</div>
				<div className="identifiers-container__col">
					<RoleBox roles={INTERNAL_ROLES}>
						<Select name="client_firm_id" label="Firm Name" value={props.quote.client_firm_id} onChange={firmChanged} options={props.firms} error={props.errors.client_firm_id} />
					</RoleBox>
					<Select
						name="client_id"
						label="Client"
						value={props.quote.client_id}
						onChange={fieldChange}
						options={props.clients}
						required={requiredQuoteDetailsField(props.quote.service_id, props.services, 'client_id', user.roleType === ROLE_TYPES.EXTERNAL)}
						error={props.errors.client_id}
					/>
				</div>
				<div className="identifiers-container__col">
					<RoleBox roles={INTERNAL_ROLES}>
						{
							<div className="firm-notes">
								<div className="firm-notes__title">Notes</div>
								<div>{props.quote.firm_notes}</div>
							</div>
						}
					</RoleBox>
				</div>
				{showAppNumberModal ?
					<ApplicationNumberFormatModal footerActions={appNumberModalActions} />
				:	null}
				{showWipoModal ?
					<QuoteDetailsWipoModal
						closeHandler={closeWipoModalData}
						wipoData={wipoModalData}
						data={props.quote}
						quoteId={props.quote.id}
						overwriteDataHandler={overwriteDataHandler}
						service={wipoService(props.quote.service_id)}
						languages={props.languages}
					/>
				:	null}
				{chinaExtensionModal ?
					<ChinaExtensionModal footerActions={chinaExtensionModalActions} />
				:	null}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		quote: state.quote,
		wipo: state.wipo,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setQuoteData: (data) => {
			dispatch(setQuoteData(data));
		},
		setWipoData: (data) => {
			dispatch(setWipoData(data));
		},
		setQuoteDataFromObject: (data) => {
			dispatch(setQuoteDataFromObject(data));
		},
	};
};

const Identifiers = connect(mapStateToProps, mapDispatchToProps)(IdentifiersBase);

export default Identifiers;
