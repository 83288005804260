import React, { useState, useEffect } from 'react';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AppTemplate from 'com/templates/ApplicationTemplate';
import { AppRoute } from 'com/util/AppRoute';
import Routes from 'data/routes';
import { RoleBox } from 'com/util/RoleBox';

import LoaderOverlay from 'com/ui/LoaderOverlay';
import CaseDetailsSidebar from 'com/widgets/CaseDetailsSidebar';
import CaseDetailsInstructed from './CaseDetailsInstructed';
import CaseDetailsUnInstructed from './CaseDetailsUnInstructed';
import CaseDetailsSendEmail from './CaseDetailsSendEmail';
import InstructionDeadlineModal from 'com/widgets/InstructionDeadlineModal';

import Cases from 'services/rest/cases';
import Users from 'services/rest/users';
import Settings from 'services/rest/settings';
import { validateInstructionDeadline } from 'services/validators/quotes';

// import Documents from "services/rest/documents";
import { DOCUMENT_OBJECT_TYPE, CASE_DIRECTIONS, EXTERNAL_ROLES, STATUS_COLORS, CaseRegionStatus, PAGE_TITLE, SERVICE_KEYS, ROLE_TYPES, INTERNAL_ROLES } from 'data/constants';

import useTitle from 'services/hooks/useTitle';

import ICON_CALENDAR_AZAMI from 'assets/images/icons/ico_calendar_azami.svg';
import ICON_ARROW_DOWN_BLUE from 'assets/images/icons/ico_arrow_down_blue.svg';
import ICON_ARROW_UP_ORANGE from 'assets/images/icons/ico_arrow_up_orange.svg';

import './style.css';
import AppDate from 'com/util/AppDate';
import { OwnerContextProvider } from 'util/ownerContext';

const CaseDetails = () => {
	useTitle(PAGE_TITLE.CASE_DETAILS);
	const uploadDocumentsEmailPayloadInit = {
		case_number: '',
		case_id: '',
		firm_name: '',
		account_executive_id: '',
		path: '',
	};
	const user = useSelector((state) => state.auth.user);
	const [showLoader, setShowLoader] = useState(false);
	const [showSubNav, setShowSubNav] = useState(true);
	const [title, setTitle] = useState('Case Details');
	const [caseDetails, setCaseDetails] = useState({
		regions: [],
	});
	const [instructedRegions, setInstructedRegions] = useState([]);
	const [caseStatuses, setCaseStatuses] = useState([]);
	const [notInstructedRegions, setNotInstructedRegions] = useState([]);
	const [regionsForInstruct, setRegionsForInstruct] = useState([]);
	const [filterData, setFilterData] = useState({
		order_by: [{ field: 'region_name', direction: 'up' }],
	});
	const [unInstructableCase, setUnInstructableCase] = useState(false);
	const [instructionDeadlineModal, setInstructionDeadlineModal] = useState(false);
	const [serviceForModal, setServiceForModal] = useState(false);
	const [uploadDocumentsEmailPayload, setUploadDocumentsEmailPayload] = useState(uploadDocumentsEmailPayloadInit);
	const [hideNotInstruct, setHideNotInstruct] = useState(false);
	const caseId = useParams().id;
	const [clientUserData, setUserData] = useState({});
	const locationPartials = useLocation().pathname.split('/');

	const getOverAllStatus = (regions) => {
		if (!regions) return '';
		// filter only regions that already have milestone
		regions = regions.filter((r) => r.mil_order !== null);
		const sortedRegions = regions.sort((a, b) => a.mil_order - b.mil_order);
		if (sortedRegions.length === 0) {
			return '';
		}
		return sortedRegions[0].status;
	};

	const fetchData = async (filter = {}) => {
		try {
			// setShowLoader(true);
			if (filter.order_by) {
				filter = {
					...filter,
					order_by: filter.order_by.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(','),
				};
			}
			const caseData = await Cases.GetOne(caseId, filter);
			let clientUser = await Users.GetOne(caseData.client_id);
			setUserData(clientUser);
			setUploadDocumentsEmailPayload({
				case_number: caseData.case_number,
				case_id: caseData.id,
				firm_name: caseData.client_firm_name,
				account_executive_id: caseData.client_account_executive_id,
				path: 'case-details',
			});
			if (caseData?.due_date && user.roleType === ROLE_TYPES.EXTERNAL) {
				let servicesData = await Settings.GetServices();
				let unInstructable = validateInstructionsDeadline(caseData.due_date, caseData.service_id, servicesData, caseData.priority_type);
				let service = servicesData.find((s) => s.id === caseData.service_id);
				if (unInstructable) {
					if (service.key === SERVICE_KEYS.DESIGN) {
						setServiceForModal(true);
					}
					setUnInstructableCase(true);
				}
			}

			setCaseDetails(caseData);
			const dueDate = earliestDueDate(caseData.regions);
			caseData.due_date = dueDate;
			// map client and client ref.number to each region
			caseData.regions.map((r) => {
				r.client = caseData.client_name;
				r.client_ref_number = r.client_ref_number || caseData.client_ref_number;
				r.client_firm_name = caseData.client_firm_name;
			});
			const instrucedRegionsData = caseData.regions.filter((r) => r.status !== CaseRegionStatus.UNINSTRUCTED);
			const notInstructedRegionsData = caseData.regions.filter((r) => r.status === CaseRegionStatus.UNINSTRUCTED);
			setInstructedRegions(instrucedRegionsData);
			setNotInstructedRegions(notInstructedRegionsData);
			setShowLoader(false);

			if (locationPartials.at(-1) === 'email') {
				setTitle('Send Case Email');
				setShowSubNav(false);
			}
		} catch (error) {
			setShowLoader(false);
			console.log(error);
		}
	};

	function earliestDueDate(regions) {
		if (regions.length > 0) {
			const sortedByDate = regions
				.filter((r) => r.due_date !== null)
				.sort((a, b) => {
					return new Date(a.due_date) - new Date(b.due_date);
				});
			return sortedByDate.length > 0 ? sortedByDate[0].due_date : null;
		}
		return null;
	}
	const overallStatus = getOverAllStatus(caseDetails.regions);

	useEffect(async () => {
		await fetchData(filterData);
		const caseStatusesData = await Settings.GetStatuses({ internal: true });
		setCaseStatuses(caseStatusesData);
	}, []);

	const validateInstructionsDeadline = (date, service_id, services, priority_type) => {
		let invalid_deadline = false;
		invalid_deadline = validateInstructionDeadline(new Date(date), service_id, services, priority_type);
		return invalid_deadline;
	};

	const instructionDeadlineModalClose = () => {
		setInstructionDeadlineModal(false);
	};

	const instructionDeadlineModalCancel = [{ primary: true, label: 'Cancel', action: instructionDeadlineModalClose, theme: 'azami-ghost' }];

	const setUninstructedTabAccess = () => {
		let allowedRoles = [...INTERNAL_ROLES, 'user', 'member', 'member_assistant', 'candidate'];
		if (caseDetails.case_direction === CASE_DIRECTIONS.SENT) allowedRoles.push('saas_user');
		return allowedRoles;
	};

	return (
		<AppTemplate title={title} headerIcon="cases" collapsibleSidebar={false}>
			<AppTemplate.Sidebar>
				<OwnerContextProvider firmId={clientUserData.firm_id}>
					<RoleBox roles={[...INTERNAL_ROLES, '$firm', '$agentOfFirm']}>
						<CaseDetailsSidebar
							caseNumber={caseDetails.case_number}
							firmName={caseDetails.client_firm_name}
							user={caseDetails.client_name}
							title="Case Details"
							overallStatus={overallStatus}
						>
							<ul className="case-details__list">
								<li>
									<span>Applicant:</span>
									<span>{caseDetails.applicant_name}</span>
								</li>
								<li>
									<span>App.no:</span>
									<span>{caseDetails.application_number}</span>
								</li>
								<li>
									<span>Ref.no:</span>
									<span>{caseDetails.client_ref_number}</span>
								</li>
								<li>
									<span>Service:</span>
									<span>{caseDetails.service_name}</span>
								</li>
								<li>
									<span>Status:</span>
									<div className="case-details__list__status-indicator" style={{ backgroundColor: STATUS_COLORS[overallStatus] }}></div>
									<span style={{ color: STATUS_COLORS[overallStatus] }}>{overallStatus}</span>
								</li>
								<RoleBox roles={EXTERNAL_ROLES}>
									<li>
										<span>Direction:</span>
										<span>
											{caseDetails.case_direction === CASE_DIRECTIONS.SENT ?
												<>
													<img src={ICON_ARROW_UP_ORANGE} alt="" />
													Sent
												</>
											:	<>
													<img src={ICON_ARROW_DOWN_BLUE} alt="" />
													Received
												</>
											}
										</span>
									</li>
								</RoleBox>
								<li>
									{/* to be implemented after estimates */}
									<span>Estimate Date:</span>
									<span>
										<img src={ICON_CALENDAR_AZAMI} alt="" />
									</span>
								</li>
								<li>
									<span>Deadline:</span>
									<span>
										<img src={ICON_CALENDAR_AZAMI} alt="" />
										<AppDate>{caseDetails.due_date}</AppDate>
									</span>
								</li>
								<li>
									<span>Created on:</span>
									<span>
										<img src={ICON_CALENDAR_AZAMI} alt="" />
										<AppDate>{caseDetails._created}</AppDate>
									</span>
								</li>
							</ul>
						</CaseDetailsSidebar>
					</RoleBox>
				</OwnerContextProvider>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<OwnerContextProvider firmId={clientUserData.firm_id}>
					<RoleBox roles={[...INTERNAL_ROLES, '$firm', '$agentOfFirm']}>
						{instructionDeadlineModal ?
							<InstructionDeadlineModal designService={serviceForModal} footerActions={instructionDeadlineModalCancel} closeHandler={instructionDeadlineModalClose} />
						:	null}
						<div className="main-screen-section white full-height no-padding">
							<LoaderOverlay showLoader={showLoader} />
							{showSubNav && (
								<header className="case-details-content__header">
									<nav>
										<NavLink to={`/case-details/${caseId}`} activeClassName="active" exact>
											Instructed
											<span>{instructedRegions.length}</span>
										</NavLink>
										<RoleBox roles={setUninstructedTabAccess()}>
											<NavLink to={`/case-details/${caseId}/uninstructed`} activeClassName="active">
												Not Instructed
												<span>{notInstructedRegions.length}</span>
											</NavLink>
										</RoleBox>
										<NavLink to={`/case-details/${caseId}/documents`} activeClassName="active">
											Case Documents
										</NavLink>
									</nav>
								</header>
							)}
							<div className="case-details-content__body">
								<AppRoute
									route={Routes.CASE_DETAILS_INSTRUCTED}
									exact
									regions={instructedRegions}
									filterData={filterData}
									setFilterData={setFilterData}
									unInstructableCase={unInstructableCase}
									setInstructionDeadlineModal={setInstructionDeadlineModal}
									caseStatuses={caseStatuses}
									fetchData={fetchData}
								/>
								<AppRoute
									route={Routes.CASE_DETAILS_UNINSTRUCTED}
									// exact
									regions={notInstructedRegions}
									filterData={filterData}
									setFilterData={setFilterData}
									regionsForInstruct={regionsForInstruct}
									setRegionsForInstruct={setRegionsForInstruct}
									fetchData={fetchData}
									unInstructableCase={unInstructableCase}
									setInstructionDeadlineModal={setInstructionDeadlineModal}
								/>
								<AppRoute
									route={Routes.CASE_DOCUMENTS}
									objectTypeRegion={DOCUMENT_OBJECT_TYPE.CASE_REGION}
									objectTypeState={DOCUMENT_OBJECT_TYPE.CASE}
									emailData={uploadDocumentsEmailPayload}
									exact
								/>
								<AppRoute route={Routes.CASE_DETAILS_SEND_EMAIL} caseDetails={caseDetails} exact />
							</div>
						</div>
						<footer className="case-details-content__footer">{/* <Button>Save Profile</Button> */}</footer>
					</RoleBox>
				</OwnerContextProvider>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default CaseDetails;
export { CaseDetailsInstructed, CaseDetailsUnInstructed, CaseDetailsSendEmail };
