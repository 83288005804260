export const random = (length) => {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const formatWebLink = (url) => {
	if (!url) return '';
	var pattern = new RegExp('^(https?)://');
	if (!pattern.test(url)) {
		url = `http://${url}`;
	}
	return url;
};

export const formatString = (str) => {
	return str
		.replace(/_/g, ' ')
		.replace(/-/g, ' ')
		.toLowerCase()
		.replace(/^\w/, (c) => c.toUpperCase());
};

// separates the thousands (e.g from 1000 to 1,000.00)
export const formatNumberWithSeparator = (num, minimumFractionDigits, maximumFractionDigits) => {
	return new Intl.NumberFormat('en-US', { minimumFractionDigits, maximumFractionDigits }).format(num);
};

export const formatEmailsStringToJSON = (str) => {
	if (Array.isArray(str)) return str;
	if (!str) return [];
	// cleaning white spaces so we return each email in correct format
	return str.replace(/\s+/g, '').split(',');
};

export const formatApplicationNumber = (application_number) => {
	let app_number = application_number.trim().toUpperCase();
	let parts = app_number.split('/');
	if (parts.length > 1) {
		//-- last part, must be 999999
		while (parts[parts.length - 1].length < 6) {
			parts[parts.length - 1] = `0${parts[parts.length - 1]}`;
		}

		//-- penultimate part
		if (!app_number.startsWith('WO') && parts[parts.length - 2].length === 4) {
			var value = parts[parts.length - 2];
			parts[parts.length - 2] = value[0] + value[1] + '20' + value[2] + value[3];
		}

		app_number = parts.join('/');
	}

	return app_number;
};

/**
 * This function rounds a given number to a specified number of decimal places using different rounding methods.
 * @param {number} value - The number to be rounded.
 * @param {number} decimals - The number of decimal places to round to.
 * @param {string} type - The rounding method to use. Possible values are "floor" (round down), "ceil" (round up), or "round" (round to nearest). Defaults to "round" if not specified.
 * @returns {number} - The rounded number.
 * @example
 * roundDecimals(6.6456583, 2); -- Returns 6.64 (rounded to 2 decimal places using default "round" method)
 * roundDecimals(6.6456583, 2, "floor"); -- Returns 6.64 (rounded down to 2 decimal places)
 * roundDecimals(6.6456583, 2, "ceil"); -- Returns 6.65 (rounded up to 2 decimal places)
 * roundDecimals(6.6456583, 2, "round"); -- Returns 6.65 (rounded to nearest integer)
 */
export const roundDecimals = (value, decimals, type = 'floor') => {
	let func = undefined;
	switch (type) {
		case 'floor':
			func = Math.floor;
			break;
		case 'ceil':
			func = Math.ceil;
			break;
		case 'round':
			func = Math.round;
			break;
		default:
			func = Math.round;
			break;
	}
	return func(value * 10 ** decimals) / 10 ** decimals;
};
// regex expression for validating email string
export const validateEmailAddress = (email) => {
	let string = email.toLowerCase();
	const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	let result = re.test(string);
	return result;
};
