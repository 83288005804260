// vendor imports
import React, { useEffect } from 'react';
import { Route, Redirect, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearUserData } from 'redux/ducks/auth';
import { ENVIRONMENTS } from 'data/constants';

export const AppRoute = ({ route, outerRef, ...rest }) => {
	const userData = useSelector((state) => state.auth.user);
	let Component = route.component;
	const dispatch = useDispatch();
	const { id } = useParams();
	useEffect(() => {
		window.addEventListener('storage', logOut);

		return function cleanup() {
			window.removeEventListener('storage', logOut);
		};
	}, [clearUserData]);

	const logOut = (e) => {
		if (e.key === 'token' && e.oldValue && !e.newValue) {
			dispatch(clearUserData());
		}
	};
	let clientOfFirm = false;
	let agentOfFirm = false;
	let saasUserOwnFirm = false;
	let firmMember = false;
	if (route.acl.includes('$firm') && id) {
		firmMember = userData.fid === id;
	}

	if (route.acl.includes('$clientOfFirm') && id) {
		clientOfFirm = userData.clientOfFirms.includes(id);
	}

	if (route.acl.includes('$agentOfFirm') && id) {
		agentOfFirm = userData.agentOfFirms.includes(id);
	}

	if (route.acl.includes('$saasUserOwnFirm') && id) {
		saasUserOwnFirm = userData.role.includes('saas_user') && userData.fid === id;
	}
	const [role, env] = route.acl.flatMap((role) => (role.includes(':') && userData.role.includes(role.split(':')[0]) ? role.split(':') : []));

	const hasAccess =
		route.acl.includes('*') ||
		route.acl.filter((r) => userData.role.includes(r)).length > 0 ||
		(role && env && userData.role.includes(role) && process.env.REACT_APP_ENVIRONMENT === ENVIRONMENTS[env.toUpperCase()]) ||
		clientOfFirm ||
		agentOfFirm ||
		saasUserOwnFirm ||
		firmMember;

	return (
		<Route
			{...rest}
			path={route.path}
			render={(props) => {
				if (hasAccess) {
					return <Component {...props} {...rest} ref={outerRef} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: '/',
								state: { from: props.location },
							}}
						/>
					);
				}
			}}
		/>
	);
};
