export const messages = {
	required: {
		title: 'Required',
		first_name: 'Required',
		last_name: 'Required',
		email: 'Required',
		phone: 'Required',
		country: 'Required',
		password: 'Required',
		password_repeat: 'Must match',

		firm_code: 'Required',
		firm_name: 'Required',
		firm_account_id: 'Required',
		city: 'Required',
		address_1: 'Required',
		state: 'Required',
		postal_code: 'Required',
		firm_country: 'Required',
		// account_executive: 'Required',
		// address_2: 'Required',
		// pricing_level: 'Required',
		// pricing_level_ep: 'Required'

		tos_agreement: 'Required',
	},
	invalid: {
		title: 'Invalid',
		first_name: 'Invalid',
		last_name: 'Invalid',
		email: 'Invalid',
		phone: 'Invalid',
		country: 'Invalid',
		password: 'Invalid',
		password_repeat: 'Invalid',

		firm_code: 'Invalid',
		firm_name: 'Invalid',
		city: 'Invalid',
		address_1: 'Invalid',
		state: 'Invalid',
		postal_code: 'Invalid',
		firm_country: 'Invalid',
		// account_executive: 'Invalid',
		// address_2: 'Invalid',
		// pricing_level: 'Invalid',
		// pricing_level_ep: 'Invalid'
	},
};
