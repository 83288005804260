//vendor imports
import { useEffect, useState } from 'react';
import { MapContainer, GeoJSON, Pane } from 'react-leaflet';
import { useSelector } from 'react-redux';

import Input from 'com/ui/Input';

import Markers from './Markers';
import RegionDetails from './RegionDetails';

import ICON_HEADER_CASES from 'assets/images/icons/ico_case_gray.svg';

import 'leaflet/dist/leaflet.css';
import './style.css';

import BI from 'services/rest/bi';

const DashboardMap = (props) => {
	const user = useSelector((state) => state.auth.user);
	const [selectedRegion, setSelectedRegion] = useState(null);
	const [regions, setRegions] = useState([]);
	const [regionsGeoJson, setRegionsGeoJson] = useState(null);

	const geoJSONStyle = {
		fillColor: '#3E369A',
		fillOpacity: 1,
		color: '#E5EDF6',
		weight: 1.3,
	};

	useEffect(() => {
		(async () => {
			try {
				let regionsData = await BI.GetFirmRegionsData(user.fid);
				let regionsGeoData = await BI.GetGeoJsonData();
				setRegions(regionsData);
				setRegionsGeoJson(regionsGeoData);
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	const onClick = (event) => {
		for (let r of regions) {
			if (r.code === event.layer.feature.properties.ISO_A2) {
				// setSelectedRegion(event.layer.feature.properties.ISO_A2);
				setSelectedRegion(r.region_id);
				return;
			}
		}
		setSelectedRegion(null);
	};

	const closePanel = () => {
		setSelectedRegion(null);
	};

	return (
		<div className="dashboard-map">
			<div className="dashboard-map__header">
				<span className="dashboard-map__header__title">
					<img className="dashboard-map__header-icon" src={ICON_HEADER_CASES} />
					My Partner Network
				</span>
				{/* <Input placeholder='Search for a partner' customClassName="map__search-input"/> */}
			</div>
			<div className="dashboard-map__map-container">
				<RegionDetails selectedRegion={selectedRegion} closePanel={closePanel} />
				<MapContainer center={[51.505, -0.09]} zoom={3} maxZoom={6} scrollWheelZoom={false}>
					<Pane name="top" style={{ zIndex: 500 }}>
						<Markers regions={regions} setSelectedRegion={setSelectedRegion} />
					</Pane>
					<Pane name="bottom" style={{ zIndex: 200 }}>
						{regionsGeoJson ?
							<GeoJSON style={geoJSONStyle} data={regionsGeoJson} eventHandlers={{ click: onClick }} />
						:	null}
					</Pane>
				</MapContainer>
			</div>
		</div>
	);
};

export default DashboardMap;
