import React, { useEffect, useState } from 'react';

import Button from 'com/ui/Button';
import Select from 'com/ui/Select';
import Modal from 'com/widgets/Modal';
import LoaderOverlay from 'com/ui/LoaderOverlay';

import ICON_ARCHIVE_GREY from 'assets/images/icons/ico_archive_grey.svg';
import ICON_UNARCHIVE_GREY from 'assets/images/icons/ico_unarchive_grey.svg';
import ICON_DELETE from 'assets/images/icons/ico_btn_delete_darkblue.svg';

import Settings from 'services/rest/settings';
import Firms from 'services/rest/firms';
import { getFlag } from 'data/flags';

import './style.css';
import { RoleBox } from 'com/util/RoleBox';

const RegionsList = (props) => {
	const regionFilterInit = [
		{ label: 'All', value: 'all' },
		{ label: 'Archived', value: 'archived' },
	];

	const [regions, setRegions] = useState([]);
	const [addMode, setAddMode] = useState(false);
	const [region, setRegion] = useState({ label: '', value: '' });
	const [countriesRaw, setCountriesRaw] = useState([]);
	const [countries, setCountries] = useState([]);
	const [showRemoveRegionModal, setShowRemoveRegionModal] = useState(false);
	const [removingRegion, setRemovingRegion] = useState(null);
	const [showLoader, setShowLoader] = useState(false);
	const [regionFilterOptions, setRegionFilterOptions] = useState(regionFilterInit);
	const [regionFilter, setRegionFilter] = useState('all');

	useEffect(async () => {
		await getDropdownData();
	}, []);

	useEffect(async () => {
		await getDropdownData();
	}, [props.firmID]);

	useEffect(() => {
		if (props.values) {
			setRegions(
				props.values.map((v) => {
					return { label: v.region_name, value: v.region_code, is_archived: v.is_archived };
				}),
			);
		}
	}, [props.values]);

	useEffect(() => {
		// check that removingRegion state is updated before opening modal
		setShowRemoveRegionModal((prevState) => {
			return removingRegion ? true : false;
		});
	}, [removingRegion]);

	const getDropdownData = async () => {
		let data = null;
		try {
			if (props.regionsList === 'firm' && props.firmID) {
				setShowLoader(true);
				let out = await Firms.GetRegions(props.firmID);
				data = out.map((d) => {
					return { id: d.region_id, name: d.region_name, code: d.region_code, firm_archived: d.is_archived };
				});
			} else if (props.regionsList === 'all') {
				setShowLoader(true);
				data = await Settings.GetRegions();
			}
			if (data) {
				setCountriesRaw(data);
				setCountries(
					data
						.filter((c) => {
							if (props.regionsList === 'firm' && c.firm_archived) {
								return false;
							}
							return c;
						})
						.map((c) => {
							return { label: c.name, value: c.code };
						}),
				);

				setRegion({ label: data[0].name, value: data[0].code });
			}
			setShowLoader(false);
		} catch (err) {
			console.log(err);
			setShowLoader(false);
		}
	};

	const showHideForm = () => {
		setAddMode(!addMode);
	};

	const addCountry = () => {
		if (regions.filter((r) => r.value === region.value).length === 0) {
			let country = countriesRaw.filter((c) => c.code === region.value)[0];
			props.onChange(country, 'add');
		}
	};

	const archiveCountryBtn = (e) => {
		setRemovingRegion((prevState) => {
			return regions.filter((r) => r.value === e.target.parentNode.dataset.regionCode)[0];
		});
	};

	const regionChange = (e) => {
		setRegion(
			countries.filter((r) => {
				let match = r.value.toLowerCase() === e.target.value.toLowerCase();
				return match;
			})[0],
		);
	};

	const closePopupHandler = () => {
		setShowRemoveRegionModal(false);
		setRemovingRegion(null);
	};

	const archiveCountry = () => {
		let country = countriesRaw.filter((c) => c.code === removingRegion.value)[0];
		props.onChange(country, 'archive');
		setShowRemoveRegionModal(false);
	};

	const onFilterChange = (e) => {
		setRegionFilter(e.target.value);
	};

	const popupFooterActions = [
		{ primary: false, label: 'Cancel', action: closePopupHandler, theme: 'azami-ghost' },
		{ primary: true, label: 'Confirm', action: archiveCountry, theme: 'azami-blue' },
	];

	return (
		<div className="regions-list">
			<LoaderOverlay showLoader={showLoader} />
			{props.title ?
				<span className="regions-list__title">{props.title}</span>
			:	null}
			<div className="regions-list__header">
				<span>Region</span>
				<div className="regions-list__header__filter">
					<span>Show:</span>
					<select value={regionFilter} onChange={onFilterChange}>
						{regionFilterOptions.map((r, i) => (
							<option value={r.value} key={i}>
								{r.label}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="regions-list__items">
				{regions.map((r) => {
					let flag = getFlag(r.value);
					if (regionFilter === 'archived' && !r.is_archived) {
						return null;
					}
					return (
						<div className="regions-list__item" key={r.value}>
							<div className={`${r.is_archived ? 'regions-list__item__archived' : ''}`}>
								<img src={flag} className="regions-list__item__flag" />
								<span className="regions-list__item__name">{r.label}</span>
							</div>
							{props.editable && (
								<RoleBox roles={['admin', 'sales', 'sales_manager']}>
									<button className="regions-list__item__button" data-region-code={r.value} onClick={archiveCountryBtn}>
										<img src={r.is_archived ? ICON_UNARCHIVE_GREY : ICON_ARCHIVE_GREY} title={r.is_archived ? 'Unarchive' : 'Archive'} />
									</button>
								</RoleBox>
							)}
						</div>
					);
				})}
			</div>

			{props.editable &&
				(!addMode ?
					<Button forRoles={['$firm', 'admin', 'sales', 'sales_manager', 'saas_user']} theme="lightblue" className="regions-list__btn-add" onClick={showHideForm}>
						Add Region
					</Button>
				:	<div className="regions-list__form">
						<Select customClassName="regions-list__form__countries-select" options={countries} value={region.value ? region.value : ''} onChange={regionChange} />
						<Button onClick={addCountry}>Add</Button>
						<Button theme="ghost" onClick={showHideForm}>
							Cancel
						</Button>
					</div>)}
			<RoleBox roles={['admin', 'sales', 'sales_manager']}>
				{showRemoveRegionModal && (
					<Modal
						title={removingRegion.is_archived ? 'Unarchive Region' : 'Archive Region'}
						// icon={ICON_DELETE}
						closeHandler={closePopupHandler}
						footerActions={popupFooterActions}
					>
						{`Are you sure you want to ${showRemoveRegionModal && removingRegion.is_archived ? 'unarchive' : 'archive'} ${removingRegion.label} for ${props.name}?`}
					</Modal>
				)}
			</RoleBox>
		</div>
	);
};

export default RegionsList;
